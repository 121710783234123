<template>
  <div>
    <v-row v-if="showTopRow || !disabledSlotTitle">
      <v-col class="d-flex align-center justify-space-between flex-wrap">
        <div class="d-flex align-center">
          <h1 class="page-title" v-if="this.$validate.DataValid(pageTitle)">{{ pageTitle }}</h1>
          <slot name="action-slot"></slot>
        </div>
        <div class="top-right-class">
          <slot name="top-right-slot"></slot>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="showBottomLeftAndRightRow || showBackButton">
      <v-col class="d-flex align-center justify-space-between flex-wrap mb-4">
        <div class="bottom-left-class">
          <v-btn
            color="colorGrey"
            depressed
            @click="$router.back()"
            v-if="showBackButton"
            class="mr-2 white--text"
            :disabled="pageLoading"
          >
            <v-icon left class="fi fi-rr-angle-square-left back-button-icon"></v-icon>
            {{ $t('back') }}
          </v-btn>
          <slot name="bottom-left-slot"></slot>
        </div>
        <div class="bottom-right-class">
          <slot name="bottom-right-slot"></slot>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
export default {
  name: 'PageHeaderSlot',
  props: {
    title: {
      type: String,
      default: '',
    },
    disabledSlotTitle: {
      required: false,
      type: Boolean,
      default: false,
    },
    showBackButton: {
      required: false,
      type: Boolean,
      default: false,
    },
    showTopRow: {
      required: false,
      type: Boolean,
      default: false,
    },
    showBottomLeftAndRightRow: {
      required: false,
      type: Boolean,
      default: true,
    },
    isLoading: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mdiChevronLeft,
  }),
  computed: {
    pageTitle() {
      let result = '';

      if (!this.disabledSlotTitle) {
        if (this.$validate.DataValid(this.title)) {
          result = this.title;
        } else if (this.$route.meta.title !== undefined) {
          result = this.$route.meta.title
        }
      }

      return result
    },
    pageLoading() {
      return this.isLoading || this.$store.getters.isLoading || this.$store.getters.isUploading;
    },
  },
}
</script>

<style lang="scss" scoped>
.page-title {
  /* 18px */
  font-size: 1.125rem;
  font-weight: 500;
}

.back-button-icon {
  font-size: 14px !important;
}

.top-right-class {
  /* d-flex align-center my-2 ml-2*/
  display: flex;
  align-content: center;
  margin-left: 8px;
}

.bottom-left-class {
  //d-flex align-center
  display: flex;
  align-content: center;
}

.bottom-right-class {
  // d-flex align-center
  display: flex;
  align-content: center;
}
</style>