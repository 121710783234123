/**
 * user_type：
 * developer
 * manager 管理員
 * admin 行政
 * tutor 導師
 */

export const cannotAccessList = {
  'PayTuitionList': ['tutor', 'student'],
  'PayTuitionPayment': ['tutor', 'student'],
  'Timetable': ['student'],
  'CreateClass': ['tutor','student'],
  'ClassDetails': ['tutor','student'],
  'StudentList': ['student'],
  'StudentDetails': ['student'],
  'StudentUserDetails': ['developer', 'manager', 'admin', 'tutor'],
  'CreateStudent': ['tutor','student'],
  'StudentAttendance': ['student'],
  'StudentUserAttendance': ['developer', 'manager', 'admin', 'tutor'],
  'CourseList': ['student'],
  'CourseDetails': ['tutor','student'],
  'CreateCourse': ['tutor','student'],
  'CourseCategoryList': ['student'],
  'CreateCourseCategory': ['tutor','student'],
  'CourseCategoryDetails': ['tutor','student'],
  'OrderList': ['tutor', 'student'],
  'StudentUserOrderList': ['developer', 'manager', 'admin', 'tutor'],
  'ProductList': ['student'],
  'ProductDetails': ['tutor','student'],
  'CreateProduct': ['tutor','student'],
  'ProductCategoryList': ['student'],
  'CreateProductCategory': ['tutor','student'],
  'ProductCategoryDetails': ['tutor','student'],
  'ClassroomList': ['student'],
  'ClassroomDetails': ['tutor','student'],
  'CreateClassroom': ['tutor','student'],
  'SchoolList': ['student'],
  'SchoolDetails': ['tutor','student'],
  'CreateSchool': ['tutor','student'],
  'TutorList': ['tutor','student'],
  'TutorDetails': ['tutor','student'],
  'CreateTutor': ['tutor','student'],
  'PromotionList': ['student'],
  'Setting': ['tutor','student'],
  'EnquiryList': ['student'],
  'PackageList': ['student'],
  'PackageDetails': ['tutor','student'],
  'CreatePackage': ['tutor','student'],
  // action
  'editStudent': ['tutor','student'],
  'upgradeStudent': ['tutor','student'], // 學生升級
  'remissStudent': ['tutor','student'], // 重新入學
  'quitStudent': ['tutor','student'], // 學生退學
  'refund': ['tutor','student'] // 退款
}

export const defaultPage = {
  developer: '/pay-tuition',
  manager: '/pay-tuition',
  admin: '/pay-tuition',
  tutor: '/timetable',
  student: '/student-info'
}
