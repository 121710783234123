/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-promise-reject-errors */
// API request usign Axios library

import axios from 'axios'

const apiEndpoint = window.location.hostname.indexOf('grandfieldlifeeducation.com') >= 0 ? 'https://api.grandfieldlifeeducation.com/request.php' : 'https://tutoring.innpressionhost.com/request.php';

export default {
  install(Vue, options) {
    Vue.prototype.$XHR = {
      // POST API request
      post(payload, uploadProgress = null) {
        return new Promise((resolve, reject) => {
          const bodyFormData = new FormData()

          for (const e in payload) {
            if (Object.prototype.hasOwnProperty.call(payload, e)) {
              bodyFormData.append(e, payload[e])
            }
          }

          const config = {
            method: 'post',
            url: apiEndpoint,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
          }

          if (uploadProgress != null) {
            config.onUploadProgress = uploadProgress
          }

          axios(config).then(response => {
            // handle server response
            if (response.data !== undefined && response.data.status !== undefined) {
              if (response.data.status === 'success') {
                resolve(response.data)
              } else {
                console.error('[API] POST failed (non-successful result)')
                reject(response.data)
              }
            } else {
              console.error('[API] POST failed (missing data or result)')
              reject(null)
            }

          }).catch(response => {
            // handle error
            console.error('[API] POST failed (connection exception)')
            reject(null)
          })
        })
      },

      async api(key, passObj) {
        const params = new URLSearchParams();
        params.set(key, JSON.stringify(passObj));
        const response = await axios.post(apiEndpoint, params.toString());
        const result = response.data;
        if (result.status === 'success' || result.result === 'success') {
          let data = null;
          if (result.status === 'success') {
            data = result.result;
          } else {
            data = result.data;
          }
          if (result.total !== undefined && result.total !== null && result.total !== '') {
            return Promise.resolve({ data: data, total: result.total });
          }

          return Promise.resolve(data);

        } else if ((result.status === 'fail' && result.result) || (result.result === 'fail' && result.data)) {
          let data = null;
          if (result.status === 'fail') {
            data = result.result;
          } else {
            data = result.data;
          }

          return Promise.reject({
            "result": "fail",
            "data": typeof data === 'string' ? data.trim() : JSON.stringify(data)
          })
        } else if (key === 'get_general_setting') {
          if (result.logo !== undefined && result.custom_text !== undefined) {
            return Promise.resolve(result);
          }
        }

        return Promise.reject({
          "result": "fail",
          "data": "發生錯誤，請與我們聯絡"
        })
      },
    }
  },
}
