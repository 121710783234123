<template>
  <v-dialog
    content-class="custom-dialog"
    v-model="openDialog"
    persistent
    :max-width="maxWidth"
    :scrollable="enableScroll"
    :eager="eager"
  >
    <v-card>
      <v-toolbar
        :color="isError ? 'error' : 'primary'"
        height="50px"
        class="custom-dialog-toolbar px-3"
        v-if="!hideToolBar"
      >
        <v-toolbar-title class="white--text toolbar-title" v-if="$validate.DataValid(title)">
          <v-icon v-if="isError" class="mr-1 my-1" size="20" color="colorWhite">{{
            icon.mdiAlertCircleOutline
          }}</v-icon>
          {{ $t(title) }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="white" small class="mr-2" @click.prevent="dialogAction(false)" v-if="enableClose">
          <v-icon>{{ icon.mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text :class="cardContentClass" class="colorBlack--text">
        <slot></slot>
      </v-card-text>
      <v-card-actions class="mt-6" :class="`justify-${btnAlign}`" v-if="!hideAction">
        <v-btn
          depressed
          color="darken-1"
          class="primary"
          text
          @click.prevent="dialogAction(true)"
          :loading="loading"
          :disabled="!enableAction"
        >
          {{ $t(confirmBtnText) }}
        </v-btn>
        <v-btn
          v-if="enableCancel"
          depressed
          color="darken-1"
          class="outline-btn"
          text
          @click.prevent="dialogAction(false)"
          :loading="loading"
          :disabled="!enableAction"
        >
          {{ $t(cancelBtnText) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiAlertCircleOutline } from '@mdi/js'
export default {
  name: 'DialogLayout',
  props: {
    openDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    maxWidth: {
      default: 500,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    hideToolBar: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideAction: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    enableScroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    confirmBtnText: {
      type: String,
      required: false,
      default: 'confirm',
    },
    cancelBtnText: {
      type: String,
      required: false,
      default: 'cancel',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableAction: {
      type: Boolean,
      required: false,
      default: true,
    },
    btnAlign: {
      type: String,
      required: false,
      default: 'start',
    },
    cardContentClass: {
      type: String,
      required: false,
      default: 'pa-3',
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    eager: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    icon: {
      mdiClose,
      mdiAlertCircleOutline,
    },
  }),
  methods: {
    dialogAction(action) {
      this.$emit('close', action)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .custom-dialog {
  & .custom-dialog-toolbar {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    box-shadow: none !important;

    & > .v-toolbar__content {
      padding: 0;
    }
  }
}
</style>
