<template>
  <DialogLayout
    title="message.dataNotSaved"
    :openDialog="showDialog"
    :maxWidth="500"
    btnAlign="center"
    enableCancel
    cardContentClass="px-3 pt-8 pb-4"
    @close="_dialogAction"
  >{{ $t('message.confirmToLeaveWithoutDataSaving') }}</DialogLayout>
</template>

<script>
import DialogLayout from '@/components/layout/DialogLayout.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ConfirmLeaveDialog',
  components: {
    DialogLayout,
  },
  computed: {
    ...mapState({
      toLink: state => state.leaveDialog.toLink,
    }),
    showDialog() {
      return this.$store.state.leaveDialog.showDialog
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions(['setShowLeaveDialog'], 'leaveDialog'),
    async _dialogAction(action) {
      if (action === true) {
        if (this.toLink) {
          await this.$store.dispatch('setDataIsUpdated', false)
          this.$router.replace(this.toLink)
          this.setShowLeaveDialog(false)
        }
      } else {
        this.setShowLeaveDialog(false)
      }
    },
  },
}
</script>
