import '@/plugins/vue-composition-api'
import '@/styles/fonts.scss'
import '@/styles/styles.scss'
import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import api from './api'
import TokenMixin from '@/mixins/TokenMixin'
import PageHeaderSlot from './components/PageHeaderSlot.vue'
import Fetcher from '@/prototype/Fetcher'
import Formatter from '@/prototype/Formatter'
import Validator from '@/prototype/Validator'
import CommonFn from '@/prototype/CommonFn'
import VueClipboard from 'vue-clipboard2'

import i18n from "@/i18n"; // 引入 I18n 套件

Vue.config.productionTip = false

Vue.use(api)
Vue.use(CommonFn)
Vue.use(Validator)
Vue.use(Formatter)
Vue.use(Fetcher)
Vue.mixin(TokenMixin)
Vue.use(VueClipboard)

Vue.component('PageHeaderSlot', PageHeaderSlot)
const pathPrefix = window.location.hostname.indexOf('grandfieldlifeeducation.com') >= 0 ? 'https://system.grandfieldlifeeducation.com/' : 'https://tutoring.innpressionhost.com/';
Vue.prototype.$mediaPath = pathPrefix + 'media/';
Vue.prototype.$reportPath = pathPrefix + 'report/';

Vue.prototype.$settings = {
  startTime: '08:00',
  endTime: '23:00',
  interval: 30,
}

const genderOptions = [
  { value: 'M', text: i18n.t('genderOption.M') },
  { value: 'F', text: i18n.t('genderOption.F') },
];
Vue.prototype.$genderOptions = genderOptions;

const subjectOptions = [
  { value: 'chinese', text: i18n.t('subjectOption.chinese') },
  { value: 'english', text: i18n.t('subjectOption.english') },
  { value: 'mathematics', text: i18n.t('subjectOption.mathematics') },
  { value: 'general_studies', text: i18n.t('subjectOption.general_studies') },
  { value: 'economy', text: i18n.t('subjectOption.economy') },
  { value: 'biology', text: i18n.t('subjectOption.biology') },
  { value: 'chemistry', text: i18n.t('subjectOption.chemistry') },
  { value: 'physic', text: i18n.t('subjectOption.physic') },
  { value: 'citizenship_social_development', text: i18n.t('subjectOption.citizenship_social_development') },
  { value: 'geography', text: i18n.t('subjectOption.geography') },
  { value: 'history', text: i18n.t('subjectOption.history') },
  { value: 'chinese_history', text: i18n.t('subjectOption.chinese_history') },
  { value: 'others', text: i18n.t('subjectOption.others') },
];
Vue.prototype.$subjectOptions = subjectOptions;

const grades = {
  P1: i18n.t('gradeOption.P1'),
  P2: i18n.t('gradeOption.P2'),
  P3: i18n.t('gradeOption.P3'),
  P4: i18n.t('gradeOption.P4'),
  P5: i18n.t('gradeOption.P5'),
  P6: i18n.t('gradeOption.P6'),
  S1: i18n.t('gradeOption.S1'),
  S2: i18n.t('gradeOption.S2'),
  S3: i18n.t('gradeOption.S3'),
  S4: i18n.t('gradeOption.S4'),
  S5: i18n.t('gradeOption.S5'),
  S6: i18n.t('gradeOption.S6'),
};
Vue.prototype.$grade = grades;
Vue.prototype.$gradeOptions = Object.keys(grades).map(key => {
  return { value: key, text: grades[key] };
});

const memberOptions = [
  { value: 'normal', text: i18n.t('memberLevelOption.normal') },
  { value: 'vip', text: i18n.t('memberLevelOption.vip') },
];
Vue.prototype.$memberOptions = memberOptions;

const orderType = {
  course: i18n.t('orderTypeOption.course'),
  product: i18n.t('orderTypeOption.product'),
  package: i18n.t('orderTypeOption.package'),
};
Vue.prototype.$orderType = orderType;

Vue.prototype.$salaryMethodOptions = [
  { value: 'by_lesson', text: i18n.t('byLesson') },
  { value: 'by_student_num', text: i18n.t('byStudentCount') }
];

Vue.filter('formatOrderStatus', function (value) {
  if (!value) return '';

  const status = {
    'pending': i18n.t('orderStatusOption.pending'),
    'paid': i18n.t('orderStatusOption.paid'),
    'expired': i18n.t('orderStatusOption.expired'),
    'null': i18n.t('orderStatusOption.null'),
    'processing': i18n.t('orderStatusOption.processing'),
    'debit': i18n.t('orderStatusOption.debit'),
  }

  const statusText = status[value];

  return statusText !== undefined && statusText !== null ? statusText : value;
})

Vue.filter('formatGrade', function (value) {
  if (!value) return '';

  const text = grades[value];

  return text !== undefined && text !== null ? text : value;
})

Vue.filter('formatAttendanceStatus', function (value) {
  if (!value) return '';

  const status = {
    'pending': i18n.t('attendanceStatus.pending'),
    'attend': i18n.t('attendanceStatus.attend'),
    'absent': i18n.t('attendanceStatus.absent'),
    'skip': i18n.t('attendanceStatus.skip'),
    'reschedule': i18n.t('attendanceStatus.reschedule'),
    'drop': i18n.t('attendanceStatus.drop'),
    'swap': i18n.t('attendanceStatus.swap'),
  }

  const statusText = status[value];

  return statusText !== undefined && statusText !== null ? statusText : value;
})

Vue.filter('formatOrderType', function (value) {
  if (!value) return '';

  const status = orderType;
  const statusText = status[value];
  return statusText !== undefined && statusText !== null ? statusText : value;
})

Vue.filter('formatSubject', function (value) {
  if (!value) return '';

  const target = subjectOptions.find(el => el.value === value);
  if (target) {
    return target.text;
  }

  return value;
})

Vue.filter('formatGender', function (value) {
  if (!value) return '';

  const target = genderOptions.find(el => el.value === value);
  if (target) {
    return target.text;
  }

  return value;
})

Vue.filter('formatMemberLevel', function (value) {
  if (!value) return '';

  const target = memberOptions.find(el => el.value === value);
  if (target) {
    return target.text;
  }

  return value;
})


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
