const pad = (n, l, c, r, b) => {
    var s = n.toString(),
        l = l || 2,
        r = r !== false,
        b = b !== false;
    if (s.length > 0) {
        var p = "";
        if (!isNaN(n) && s.charAt(0) == "-") {
            p = "-";
            s = s.substring(1);
        }
        while (s.length < l) {
            if (r) s = (c || "0") + s;
            else s += c || "0";
        }
        return b ? p + s : s;
    } else return "";
}

// cms: backend
const COURSE_MODEL = {
    "course_code": "course_code",
    "grade": "grade",
    "category_id": "course_category_id",
    "schools": "center"
};

const COURSE_CATEGORY_MODEL = {
    "name": "name",
    "category_code": "category_code",
    "schools": "center",
};

const CLASSROOM_MODEL = {
    "name": "name",
    "school_id": "center_id",
};

const PRODUCT_CATEGORY_MODEL = {
    "name": "name",
};

const PRODUCT_MODEL = {
    "name": "name",
    "product_code": "product_code",
    "status": "status",
    // "school_id": "center_id",
    "category_id": "category",
    "inventory": "inventory",
    "price": "price",
    "cost": "cost",
    "schools": "center_id"
};

const SCHOOL_MODEL = {
    "name": "name",
    "address": "address",
    "logo": "logo",
    "school_code": "center_code",
    "invoice_terms": "terms",
};

const STUDENT_MODEL = {
    "status": "status",
    "school_id": "center_id",
    "student_name": "student_name",
    "student_eng_name": "student_name_en",
    "address": "address",
    "gender": "gender",
    "phone": "phone",
    "birth_date": "birthday",
    "username": "username",
    "password": "password_data",
    "contact_person_1_name": "contact_1_name",
    "contact_person_1_phone": "contact_1_phone",
    "contact_person_2_name": "contact_2_name",
    "contact_person_2_phone": "contact_2_phone",
    "student_school_name": "school",
    "member_level": "level",
    "grade": "grade",
    "remark": "remark",
    "student_code": "student_code",
};

const STUDENT_SCORE_MODEL = {
    "student_id": "student_id",
    "exam_name": "name",
    "grade": "grade",
    "semester": "semester",
    "subject": "subject",
    "score": "score"
}

const TUTOR_MODEL = {
    "name": "name",
    "user_type": "staff_role",
    "schools": "center",
    "gender": "gender",
    "username": "username",
    "password": "password_data",
    "phone": "phone",
    "remark": "remark",
};

const CLASS_MODEL = { // for create lesson
    "course_id": "course_id",
    "course_code": "course_code",
    "course_name": "name",
    "tutor_id": "staff_id",
    "classroom_id": "facility_id",
    "color": "color",
    "salary_method": "salary_method", // 'by_lesson' | 'by_student_num'
    "max_student": "capacity",
    "school_id": "center_id",
    "price": "price",
    "grade": "grade",
    "lesson_type": "lesson_type",

    // For single date lesson
    "single_date": "date",
    "start_time": "start_time",
    "end_time": "end_time",

    // For general date lesson
    "regular_period": "generate_period", //  'weekly' | 'bi-weekly'
    "regular_weekdays": "weekday",
    "start_date": "start_date",
    "end_date": "end_date",
    "weekday_start_time": "weekday_start_time",
    "weekday_end_time": "weekday_end_time"
};

const LESSON_MODEL = { // for edit lesson
    "date": "date",
    "start_time": "start_time",
    "end_time": "end_time",
    "tutor_id": "staff_id",
    "classroom_id": "facility_id",
    "attachment_list": "attachment",
    "attendance_list": "attendance", // { student_id: -1, status: 'pending' | 'attend' | 'absent' }
}

const ORDER_MODEL = {
    "student_id": "student_id",
    "school_id": "center_id",
    "remark": "remark",
    "payment_method": "payment_method",
    "lesson_id_list": "lesson_id_list",
    "product_id_list": "product_id_list",
    "subtotal": "subtotal",
    "status": "status",
    "discount_type": "discount_type",
    "discount_value": "discount_value",
    "payment_image": "payment_image",
    "package_id": "package_id"
};

const PACKAGE_MODEL = {
    "name": "package_name",
    "lesson_count": "count",
    "price": "price",
    "schools": "center"
};

const convertCMSKeyToBackendKey = (payload, model) => {
    if (payload && Object.keys(payload).length > 0) {
        let obj = {};

        for (const key in payload) {
            if (model[key] !== undefined && model[key] !== null && model[key] !== '') {
                obj[model[key]] = payload[key];
            } else {
                obj[key] = payload[key];
            }
        }

        return obj;
    }

    return {};
}

// backend: cms
const CMS_STUDENT_STATUS = {
    "active": "active",
    "quit": "inactive"
}

const CMS_ORDER_STATUS = {
    "pending": "pending",
    "paid": "paid",
    "expired": "expired",
    "cancelled": "cancelled",
    "null": "null",
    "processing": "processing",
    "debit": "debit" // refund
}

const CMS_ATTENDANCE_STATUS = {
    "pending": "pending",
    "attend": "attend",
    "absent": "absent",
    "reschedule": "reschedule",
    "drop": "drop",
    "swap": "swap",
    "skip": "skip"
}

const parseAccountData = (data) => {
    if (data) {
        return {
            id: data.id,
            active: data.active,
            create_date: data.create_date,
            name: data[TUTOR_MODEL.name],
            user_type: data[TUTOR_MODEL.user_type],
            schools: data[TUTOR_MODEL.schools],
            gender: data[TUTOR_MODEL.gender],
            username: data[TUTOR_MODEL.username],
            phone: data[TUTOR_MODEL.phone],
            remark: data[TUTOR_MODEL.remark],
        }
    }

    return null;
}

const parseTutorData = (data) => {
    if (data) {
        return {
            id: data.id,
            active: data.active,
            name: data[TUTOR_MODEL.name],
            user_type: data[TUTOR_MODEL.user_type],
            schools: data[TUTOR_MODEL.schools],
            gender: data[TUTOR_MODEL.gender],
            username: data[TUTOR_MODEL.username],
            phone: data[TUTOR_MODEL.phone],
            remark: data[TUTOR_MODEL.remark]
        };
    }

    return null;
}

const parseStudentData = (data) => {
    if (data) {
        return {
            id: data.id,
            active: data.active,
            status: CMS_STUDENT_STATUS[data.status] || 'active', // active | quit
            school_id: data[STUDENT_MODEL.school_id],
            student_name: data[STUDENT_MODEL.student_name] || '', // required
            student_eng_name: data[STUDENT_MODEL.student_eng_name] || '',
            address: data[STUDENT_MODEL.address] || '',
            gender: data[STUDENT_MODEL.gender] || '',
            phone: data[STUDENT_MODEL.phone] || '', // required
            birth_date: data[STUDENT_MODEL.birth_date] || '', // YYYY-MM-DD
            username: data[STUDENT_MODEL.username] || '',
            contact_person_1_name: data[STUDENT_MODEL.contact_person_1_name],
            contact_person_1_phone: data[STUDENT_MODEL.contact_person_1_phone],
            contact_person_2_name: data[STUDENT_MODEL.contact_person_2_name],
            contact_person_2_phone: data[STUDENT_MODEL.contact_person_2_phone],
            student_school_name: data[STUDENT_MODEL.student_school_name] || '',
            member_level: data[STUDENT_MODEL.member_level] || '',
            grade: data[STUDENT_MODEL.grade] || '',
            remark: data[STUDENT_MODEL.remark] || '',
            student_code: data[STUDENT_MODEL.student_code] || '',

            reg_date: data.create_date ? data.create_date.substring(0, 10) : '',
            order_total: data.order_datas !== undefined && Array.isArray(data.order_datas) ? data.order_datas.length : null, // 要用GetStudents先會出，GetStudentById唔會有
            last_lesson_id: data.lesson ? data.lesson.id : -1,
        };
    }

    return null;
}

const parseStudentScoreData = (data) => {
    if (data) {
        return {
            id: data.id,
            create_date: `${data.create_date.substring(0, 10)} ${data.create_date.substring(11, 19)}`,
            active: data.active,
            student_id: data[STUDENT_SCORE_MODEL.student_id],
            exam_name: data[STUDENT_SCORE_MODEL.exam_name],
            grade: data[STUDENT_SCORE_MODEL.grade],
            semester: data[STUDENT_SCORE_MODEL.semester],
            subject: data[STUDENT_SCORE_MODEL.subject],
            score: data[STUDENT_SCORE_MODEL.score],
        };
    }

    return null;
}

const parseStudentAttendanceData = (data, student_id) => {
    if (data) {
        let attendStatus = 'pending';
        let targetAttendance = null;
        for (let i = data[LESSON_MODEL.attendance_list].length - 1; i >= 0; i--) {
            if (data[LESSON_MODEL.attendance_list][i].student_id === student_id) {
                targetAttendance = data[LESSON_MODEL.attendance_list][i];
                break;
            }
        }

        if (!targetAttendance) {
            attendStatus = 'drop';
        } else {
            if (!data.students.includes(student_id) && targetAttendance.status !== 'reschedule' && targetAttendance.status !== 'skip' && targetAttendance.status !== 'swap') {
                attendStatus = 'drop';
            } else {
                attendStatus = CMS_ATTENDANCE_STATUS[targetAttendance.status] || 'pending';
            }
        }

        return {
            lesson_id: data.id,
            student_id: student_id,
            date: data[LESSON_MODEL.date],
            course_code: data[CLASS_MODEL.course_code],
            course_name: data[CLASS_MODEL.course_name],
            attend_status: attendStatus,
            start_time: data[LESSON_MODEL.start_time],
            end_time: data[LESSON_MODEL.end_time],
        };
    }

    return null;
}

const parseSchoolData = (data) => {
    if (data) {
        return {
            id: data.id,
            active: data.active,
            name: data[SCHOOL_MODEL.name],
            address: data[SCHOOL_MODEL.address],
            logo: data[SCHOOL_MODEL.logo],
            school_code: data[SCHOOL_MODEL.school_code],
            invoice_terms: data[SCHOOL_MODEL.invoice_terms],
        };
    }

    return null;
}

const parseClassroomData = (data, schoolData = []) => {
    if (data) {
        let targetSchoolData = null;
        if (schoolData.length > 0) {
            targetSchoolData = schoolData.find(el => el.id === data[CLASSROOM_MODEL.school_id]);
        }
        return {
            id: data.id,
            active: data.active,
            name: data[CLASSROOM_MODEL.name],
            school_id: data[CLASSROOM_MODEL.school_id],
            school_data: targetSchoolData
        };
    }

    return null;
}

const parseProductCategoryData = (data) => {
    if (data) {
        return {
            id: data.id,
            active: data.active,
            name: data[PRODUCT_CATEGORY_MODEL.name],
        };
    }

    return null;
}

const parseProductData = (data, categoryData = []) => {
    if (data) {
        let targetCate = null;
        if (categoryData.length > 0) {
            targetCate = categoryData.find(el => el.id === data[PRODUCT_MODEL.category_id]);
        }

        return {
            id: data.id,
            active: data.active,
            status: data.status, // 'active' | 'inactive'
            name: data[PRODUCT_MODEL.name],
            product_code: data[PRODUCT_MODEL.product_code],
            schools: data[PRODUCT_MODEL.schools],
            category_id: data[PRODUCT_MODEL.category_id],
            category_data: targetCate,
            inventory: data[PRODUCT_MODEL.inventory],
            price: data[PRODUCT_MODEL.price],
            cost: data[PRODUCT_MODEL.cost],
        };
    }

    return null;
}

const parseCourseCategoryData = (data, schoolData = []) => {
    if (data) {
        const schoolList = [];

        if (schoolData.length > 0) {
            data[COURSE_CATEGORY_MODEL.schools].forEach(id => {
                const target = schoolData.find(el => el.id === id);
                if (target) {
                    schoolList.push(target);
                }
            })
        }

        return {
            id: data.id,
            active: data.active,
            name: data[COURSE_CATEGORY_MODEL.name],
            category_code: data[COURSE_CATEGORY_MODEL.category_code],
            schools: data[COURSE_CATEGORY_MODEL.schools],
            school_datas: schoolList,
        };
    }

    return null;
}

const parseCourseData = (data, schoolData = [], categoryData = []) => {
    if (data) {
        const schoolList = [];

        if (schoolData.length > 0) {
            data[COURSE_MODEL.schools].forEach(id => {
                const target = schoolData.find(el => el.id === id);
                if (target) {
                    schoolList.push(target);
                }
            })
        }

        let targetCate = null;
        if (categoryData.length > 0) {
            targetCate = categoryData.find(el => el.id === data[COURSE_MODEL.category_id]);
        }

        return {
            id: data.id,
            active: data.active,
            grade: data[COURSE_MODEL.grade],
            category_id: data[COURSE_MODEL.category_id],
            category_data: targetCate,
            schools: data[COURSE_MODEL.schools],
            school_datas: schoolList,
        }
    }

    return null;
}

const parseLessonData = (data, tutorData = [], studentData = [], attendanceLessonData = []) => {
    if (data) {
        let targetTutor = null;
        if (tutorData.length > 0) {
            targetTutor = tutorData.find(el => el.id === data[LESSON_MODEL.tutor_id]);
        }

        const activeStudents = data.students && Array.isArray(data.students) ? data.students.filter(id => id !== null) : [];

        let attendanceList = [];
        if (data[LESSON_MODEL.attendance_list] !== undefined && Array.isArray(data[LESSON_MODEL.attendance_list])) {
            const uniqueAttendanceList = [];
            for (let i = data[LESSON_MODEL.attendance_list].length - 1; i >= 0; i--) {
                const found = uniqueAttendanceList.find(el => el.student_id === data[LESSON_MODEL.attendance_list][i].student_id);
                if (!found) {
                    uniqueAttendanceList.push(data[LESSON_MODEL.attendance_list][i]);
                }
            }

            attendanceList = uniqueAttendanceList.map(el => {
                const targetStudent = studentData.find(student => student.id === el.student_id);
                const targetFromLesson = attendanceLessonData.find(lesson => lesson.id === el.from_lesson);
                return {
                    student_id: el.student_id,
                    status: CMS_ATTENDANCE_STATUS[el.status] || 'pending',
                    student_name: targetStudent ? targetStudent.student_name : '',
                    gender: targetStudent ? targetStudent.gender : '',
                    switch_from: el.from_course !== undefined && el.from_course !== null && el.from_course !== '' && el.from_course !== -1 ? el.from_course : (targetFromLesson ? `${targetFromLesson.course_name} (${targetFromLesson.date})` : null),
                    last_lesson_id: targetStudent ? targetStudent.last_lesson_id : -1,
                }
            })
        }

        attendanceList.sort((a, b) => a.student_id - b.student_id);

        const currentAttendLessonStudents = activeStudents.filter(id => {
            const found = attendanceList.find(elm => elm.student_id === id);
            return !found || (found.status !== 'absent' && found.status !== 'skip')
        })

        return {
            id: data.id,
            active: data.active,
            date: data[LESSON_MODEL.date],
            start_time: data[LESSON_MODEL.start_time],
            end_time: data[LESSON_MODEL.end_time],
            tutor_id: data[LESSON_MODEL.tutor_id],
            tutor_data: targetTutor,
            classroom_id: data[LESSON_MODEL.classroom_id],
            classroom_data: data.facility_data ? data.facility_data : null,
            attachment_list: data[LESSON_MODEL.attachment_list], // { name: '', file: '' }
            all_attendance_list: attendanceList, // included all students with any attend status
            attendance_list: attendanceList.filter(el => activeStudents.includes(el.student_id)), // attendance with student in activeStudents
            raw_attendance_list: data[LESSON_MODEL.attendance_list],
            course_id: data[CLASS_MODEL.course_id],
            course_data: data.course_data ? data.course_data : null,
            course_code: data[CLASS_MODEL.course_code],
            course_name: data[CLASS_MODEL.course_name],
            color: data[CLASS_MODEL.color],
            salary_method: data[CLASS_MODEL.salary_method],
            max_student: data[CLASS_MODEL.max_student],
            school_id: data[CLASS_MODEL.school_id],
            price: data[CLASS_MODEL.price],
            grade: data[CLASS_MODEL.grade] ? data[CLASS_MODEL.grade] : (data.course_data ? data.course_data.grade : ''),
            lesson_type: data[CLASS_MODEL.lesson_type],

            current_student_num: currentAttendLessonStudents.length, // students with status NOT equals to 'drop' | 'swap' | 'reschedule' | 'absent' | 'skip'
            student_ids: activeStudents, // students with status NOT equals to 'drop' | 'swap' | 'reschedule'
            package_used_student_id_list: data.package_quote ? data.package_quote : [],
            regular_id: data.regular_id,
            paid_student_ids: data.paid || [],
            dropped_student_ids: data.exit || []
        }
    }

    return null;
}

const paresRegularSetting = (data) => {
    if (data) {
        return {
            id: data.id,
            active: data.active,
            course_id: data[CLASS_MODEL.course_id],
            course_code: data[CLASS_MODEL.course_code],
            course_name: data[CLASS_MODEL.course_name],
            tutor_id: data[CLASS_MODEL.tutor_id],
            classroom_id: data[CLASS_MODEL.classroom_id],
            color: data[CLASS_MODEL.color],
            salary_method: data[CLASS_MODEL.salary_method],
            max_student: data[CLASS_MODEL.max_student],
            school_id: data[CLASS_MODEL.school_id],
            price: data[CLASS_MODEL.price],
            grade: data[CLASS_MODEL.grade],
            lesson_type: data[CLASS_MODEL.lesson_type],
            start_time: data[CLASS_MODEL.start_time],
            end_time: data[CLASS_MODEL.end_time],
            regular_period: data[CLASS_MODEL.regular_period],
            regular_weekdays: data[CLASS_MODEL.regular_weekdays],
            start_date: data[CLASS_MODEL.start_date],
            end_date: data[CLASS_MODEL.end_date],
        }
    }

    return null;
}

const parsePayTuitionData = (data) => {
    if (data) {
        let status = 'null';
        let status_next = 'null';
        const orders = [];
        if (data.order_datas && data.order_datas.length > 0) {
            const today = new Date();
            const nextMonth = new Date();
            nextMonth.setMonth(nextMonth.getMonth() + 1);

            // --- Filter走重覆嘅order
            data.order_datas.sort((a, b) => new Date(b.create_date).getTime() - new Date(a.create_date).getTime());
            for (let i = 0; i < data.order_datas.length; i++) {
                const found = orders.find(el => el.id === data.order_datas[i].id);
                if (!found && data.order_datas[i].lesson_datas) {
                    orders.push(data.order_datas[i]);
                }
            }

            orders.sort((a, b) => new Date(a.create_date).getTime() - new Date(b.create_date).getTime());
            for (let i = 0; i < orders.length; i++) {
                if (orders[i].lesson_datas && orders[i].lesson_datas.length) {
                    const datas = [];
                    orders[i].lesson_datas.forEach(elm => {
                        if (elm) {
                            datas.push(elm);
                        }
                    })
                    orders[i].lesson_datas = datas;
                    orders[i].lesson_datas.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
                }
            }

            // 本月訂單狀態
            for (let i = 0; i < orders.length; i++) {
                if (orders[i].lesson_datas && orders[i].lesson_datas.length) {
                    for (let j = 0; j < orders[i].lesson_datas.length; j++) {
                        const date = new Date(orders[i].lesson_datas[j].date);
                        if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth()) {
                            status = orders[i].status;
                            if (orders[i].status !== 'paid') {
                                const date = new Date();
                                const today = new Date(`${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T12:00:00+08:00`);
                                const createDate = new Date(`${orders[i].create_date.substring(0, 10)}T12:00:00+08:00`);
                                const diffDays = Math.floor((today.getTime() - createDate.getTime()) / (1000 * 3600 * 24));
    
                                if (diffDays > 30) {
                                    status = 'expired';
                                    break;
                                }
                            }
                        }
                    }
                }

                if (status === 'expired') {
                    break;
                }
            }

            // 下月訂單狀態
            for (let i = 0; i < orders.length; i++) {
                if (orders[i].lesson_datas && orders[i].lesson_datas.length) {
                    for (let j = 0; j < orders[i].lesson_datas.length; j++) {
                        const date = new Date(orders[i].lesson_datas[j].date);
                        if (date.getFullYear() === nextMonth.getFullYear() && date.getMonth() === nextMonth.getMonth()) {
                            status_next = orders[i].status;
                        }
                    }
                }
            }
        }

        return {
            student_id: data.id,
            school_id: data[STUDENT_MODEL.school_id],
            student_name: data[STUDENT_MODEL.student_name] || '',
            gender: data[STUDENT_MODEL.gender] || '',
            phone: data[STUDENT_MODEL.phone] || '',
            grade: data[STUDENT_MODEL.grade] || '',
            student_school_name: data[STUDENT_MODEL.student_school_name] || '',
            reg_date: data.create_date ? data.create_date.substring(0, 10) : '',
            order_status: CMS_ORDER_STATUS[status] || 'null',
            next_order_status: CMS_ORDER_STATUS[status_next] || 'null',
            student_code: data[STUDENT_MODEL.student_code],
        };
    }

    return null;
}

const parseOrderData = (data) => {
    if (data) {
        let order_type = '';
        if (data[ORDER_MODEL.package_id] !== undefined && data[ORDER_MODEL.package_id] !== null && data[ORDER_MODEL.package_id] > -1) {
            order_type = 'package';
        } else if (Array.isArray(data[ORDER_MODEL.product_id_list]) && data[ORDER_MODEL.product_id_list].length > 0) {
            order_type = 'product';
        } else if (Array.isArray(data[ORDER_MODEL.lesson_id_list]) && data[ORDER_MODEL.lesson_id_list].length > 0) {
            order_type = 'course';
        }

        let itemDatas = [];
        if (order_type === 'course' || order_type === 'package') {
            itemDatas = data.lesson_datas ? data.lesson_datas.map(parseLessonData) : [];
        } else if (order_type === 'product') {
            itemDatas = data.product_datas ? data.product_datas.map(parseProductData) : [];
        }

        return {
            id: data.id,
            active: data.active,
            create_date: data.create_date ? `${data.create_date.substring(0, 10)} ${data.create_date.substring(11, 19)}` : '',
            status: CMS_ORDER_STATUS[data.status] || 'pending',
            student_id: data[ORDER_MODEL.student_id],
            school_id: data[ORDER_MODEL.school_id],
            remark: data[ORDER_MODEL.remark],
            payment_method: data[ORDER_MODEL.payment_method],
            subtotal: data[ORDER_MODEL.subtotal],
            discount_type: data[ORDER_MODEL.discount_type],
            discount_value: data[ORDER_MODEL.discount_value],
            payment_image: data[ORDER_MODEL.payment_image],
            package_id: order_type === 'package' ? data[ORDER_MODEL.package_id] : -1,

            order_type: order_type,
            item_list: (order_type === 'course' || order_type === 'package') ? data[ORDER_MODEL.lesson_id_list] : (order_type === 'product' ? data[ORDER_MODEL.product_id_list] : []),
            item_datas: itemDatas,
            month: data.create_date ? data.create_date.substring(5, 7) : '',
            student_name: data.student_data ? (data.student_data[STUDENT_MODEL.student_name] || data.student_data[STUDENT_MODEL.student_eng_name]) : '',

            // -- 退款
            debit_package_count: data.debit_package_count,
            debit_lesson_ids: data.debit_lesson_ids,
            debit_product_ids: data.debit_product_ids,
            debit_amount: data.debit_amount,
            debit_remark: data.debit_remark,
        };
    }

    return null;
}

const parsePackageData = (data) => {
    if (data) {
        return {
            id: data.id,
            active: data.active,
            name: data[PACKAGE_MODEL.name],
            lesson_count: data[PACKAGE_MODEL.lesson_count],
            price: data[PACKAGE_MODEL.price],
            schools: data[PACKAGE_MODEL.schools],
        };
    }

    return null;
}

const displayError = (res) => {
    if (res.data !== undefined && res.data !== null) {
        if (typeof res.data === 'string') {
            return res.data;
        } else {
            return JSON.stringify(res.data);
        }
    }

    return JSON.stringify(res);
}

import Vue from 'vue'
import i18n from '@/i18n';
const vm = new Vue({ i18n });

export default {
    install(Vue, option) {
        Vue.prototype.$Fetcher = new (function () {
            const DataValid = Vue.prototype.$validate.DataValid;
            const devLog = Vue.prototype.$common.log;

            /**
             * 
             * @param {String} upload_data - base64
             * @param {String} upload_file_type - e.g. jpg
             */
            this.UploadFile = async function (payload) {
                try {
                    const data = await Vue.prototype.$XHR.api('upload_file', payload);
                    if (typeof data === 'string') {
                        devLog('----------> XHR [SUCCESS]: UploadFile');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: UploadFile');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UploadFile');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.Login = async function (username, password) {
                try {
                    const uploaded = {
                        username,
                        password
                    }
                    const data = await Vue.prototype.$XHR.api('staff_login', uploaded);
                    const formattedData = parseAccountData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: Login');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: Login');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: Login');
                    devLog(res);
                    switch (res.data) {
                        case 'login fail':
                            return Promise.reject(vm.$t('message.loginAccountInv'));
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            this.StudentLogin = async function (username, password) {
                try {
                    const uploaded = {
                        username,
                        password
                    }
                    const data = await Vue.prototype.$XHR.api('student_login', uploaded);
                    const formattedData = parseStudentData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: StudentLogin');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: StudentLogin');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: StudentLogin');
                    devLog(res);
                    switch (res.data) {
                        case 'login fail':
                            return Promise.reject(vm.$t('message.loginAccountInv'));
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }





            this.GetSetting = async function () {
                try {
                    const data = await Vue.prototype.$XHR.api('get_general_setting', {});
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetSetting');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetSetting');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetSetting');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ===== Required =====
             * @param {String} logo
             * @param {String} custom_text
             * ===== Optional =====
             * @param {String} phone
             * @param {String} email
             */
            this.UpdateSetting = async function (update) {
                try {
                    const payload = {
                        ...update
                    }

                    const data = await Vue.prototype.$XHR.api('edit_general_setting', payload);

                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: UpdateSetting');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateSetting');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateSetting');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }





            /**
             * 新增Account/導師
             * ===== Required =====
             * @param {String} password_data
             * @param {String} username
             * 
             */
            this.NewAccount = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, TUTOR_MODEL);
                    const uploaded = {
                        google_sync: false,
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_staff', uploaded);
                    const formattedData = parseAccountData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewAccount');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewAccount');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewAccount');
                    devLog(res);
                    switch (res.data) {
                        case "username exist":
                            return Promise.reject(vm.$t('message.accountExist'));
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            this.UpdateTutor = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, TUTOR_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('edit_staff', payload);
                    const formattedData = parseTutorData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateTutor');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateTutor');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateTutor');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * 導師管理 List
             * ===== Optional =====
             * @param {Array<id>} center
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             */
            this.GetTutors = async function (filters = {}) {
                try {
                    const data = await Vue.prototype.$XHR.api('get_staff', filters);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parseTutorData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetTutors');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetTutors');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetTutors');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetTutorsByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'staff_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id_list', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseTutorData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetTutorsByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetTutorsByIdList');
                        return Promise.resolve({ data: [], total: 0 });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetTutorsByIdList');
                    devLog(res);
                    return Promise.resolve({ data: [], total: 0 });
                }
            }

            this.GetTutorById = async function (id) {
                const payload = {
                    data_type: 'staff_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseTutorData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetTutorById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetTutorById');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetTutorById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteTutor = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_staff', { id });
                    devLog('----------> XHR [SUCCESS]: DeleteTutor');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteTutor');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.TutorChangePassword = async function (id, newPassword) {
                const payload = {
                    id: id,
                    password_data: newPassword
                }

                try {
                    await Vue.prototype.$XHR.api('edit_staff', payload);
                    devLog('----------> XHR [SUCCESS]: TutorChangePassword');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: TutorChangePassword');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }





            /**
             * 新增分校
             * ==== Required ====
             * @param {String} name 
             * @param {String} address
             * ===== Optional =====
             * @param {String} logo
             */
            this.NewSchool = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, SCHOOL_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_center', uploaded);
                    const formattedData = parseSchoolData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewSchool');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewSchool');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewSchool');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateSchool = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, SCHOOL_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('edit_center', payload);
                    const formattedData = parseSchoolData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateSchool');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateSchool');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateSchool');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * 分校管理 List
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             */
            this.GetSchools = async function (filters = {}) {
                const payload = {
                    data_type: 'center_data',
                    ...filters
                };
                if (payload['filter_item'] && payload['filter_item'].length < 1) {
                    delete payload['filter_item'];
                }

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parseSchoolData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetSchools');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetSchools');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetSchools');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetSchoolsByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'center_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id_list', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseSchoolData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetSchoolsByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetSchoolsByIdList');
                        return Promise.resolve({ data: [], total: 0 });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetSchoolsByIdList');
                    devLog(res);
                    return Promise.resolve({ data: [], total: 0 });
                }
            }

            this.GetSchoolById = async function (id) {
                const payload = {
                    data_type: 'center_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseSchoolData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetSchoolById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetSchoolById');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetSchoolById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteSchool = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_center', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteSchool');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteSchool');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }





            /**
             * 學生管理
             * ======= Optional ======
             * @param {String} search - search keyword
             */
            /**
             * 學生管理
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             * @param {String} search - search keyword
             * @param {Array<{key: string, value: any}>} filter_item
             * filter_item key:
             * - 'name': string
             * - 'id': number
             * - 'phone': string
             * - 'grade': string
             * - 'school': string
             * - 'level': string
             * - 'create_date': string
             */
            this.GetStudents = async function (filters = {}) {
                const payload = {
                    data_type: 'student_data',
                    ...filters
                };
                if (payload['filter_item'] && payload['filter_item'].length < 1) {
                    delete payload['filter_item'];
                }

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parseStudentData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetStudents');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudents');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudents');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetStudentsByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'student_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id_list', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseStudentData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetStudentsByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentsByIdList');
                        return Promise.resolve({ data: [], total: 0 });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentsByIdList');
                    devLog(res);
                    return Promise.resolve({ data: [], total: 0 });
                }
            }

            this.GetStudentsWithLastLessonByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'student_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_student_and_lesson', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseStudentData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetStudentsWithLastLessonByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentsWithLastLessonByIdList');
                        return Promise.resolve({ data: [], total: 0 });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentsWithLastLessonByIdList');
                    devLog(res);
                    return Promise.resolve({ data: [], total: 0 });
                }
            }

            /**
             * 新增學生
             * ===== Required =====
             * @param {String} password_data
             * @param {String} username
             * 
             */
            this.NewStudent = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, STUDENT_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_student', uploaded);

                    const formattedData = parseStudentData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewStudent');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewStudent');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewStudent');
                    devLog(res);
                    switch (res.data) {
                        case "username exist":
                            return Promise.reject(vm.$t('message.accountExist'));
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            this.UpdateStudent = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, STUDENT_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('edit_student', payload);
                    const formattedData = parseStudentData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateStudent');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateStudent');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateStudent');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetStudentById = async function (id) {
                const payload = {
                    data_type: 'student_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseStudentData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetStudentById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentById');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.StudentQuit = async function (id) {
                const payload = {
                    id: id,
                    status: 'quit',
                }

                try {
                    const data = await Vue.prototype.$XHR.api('edit_student', payload);
                    const formattedData = parseStudentData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: StudentQuit');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: StudentQuit');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: StudentQuit');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.StudentRemission = async function (id) {
                const payload = {
                    id: id,
                    status: 'active',
                }

                try {
                    const data = await Vue.prototype.$XHR.api('edit_student', payload);
                    const formattedData = parseStudentData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: StudentRemission');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: StudentRemission');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: StudentRemission');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.StudentChangePassword = async function (id, newPassword) {
                const payload = {
                    id: id,
                    password_data: newPassword
                }

                try {
                    await Vue.prototype.$XHR.api('edit_student', payload);
                    devLog('----------> XHR [SUCCESS]: StudentChangePassword');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: StudentChangePassword');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpgradeStudents = async function () {
                try {
                    await Vue.prototype.$XHR.api('upgrade_student', {});
                    devLog('----------> XHR [SUCCESS]: UpgradeStudents');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpgradeStudents');
                    devLog(res);
                    switch (res.data) {
                        case 'already upgrade in 30day':
                            return Promise.reject(vm.$t('message.cannotUpgradeWithinDays'));
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            /**
             * 學業成績
             * ====== required =====
             * @param {number} student_id
             */
            this.NewStudentScore = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, STUDENT_SCORE_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_score', uploaded);
                    const formattedData = parseStudentScoreData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewStudentScore');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewStudentScore');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewStudentScore');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateStudentScore = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, STUDENT_SCORE_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('edit_score', payload);
                    const formattedData = parseStudentScoreData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateStudentScore');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateStudentScore');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateStudentScore');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteStudentScore = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_score', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteStudentScore');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteStudentScore');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             * @param {Array<{key: string, value: any}>} filter_item
             * filter_item key:
             * - 'student_id': number
             */
            this.GetStudentScores = async function (filters) {
                const payload = {
                    data_type: 'score_data',
                    ...filters
                };
                if (payload['filter_item'] && payload['filter_item'].length < 1) {
                    delete payload['filter_item'];
                }

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parseStudentScoreData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetStudentScores');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentScores');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentScores');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            /**
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {Array<{key: string, value: any}>} filter_item
             * filter_item key:
             * - 'date': string
             */
            this.GetStudentAttendances = async function (student_id, filters) {
                const filterItems = [
                    ...filters['filter_item'],
                    { key: 'student_id', value: student_id }
                ];
                delete filters['filter_item'];

                const payload = {
                    data_type: 'lesson_data',
                    filter_item: filterItems,
                    order_by: 'date',
                    sort_dir: 'desc',
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(el => parseStudentAttendanceData(el, student_id));
                        const returnedData = {
                            data: formattedData,
                            total: data['total'],
                            attend: formattedData.filter(el => el.attend_status === 'attend').length,
                            absent: formattedData.filter(el => el.attend_status === 'absent').length,
                            pending: formattedData.filter(el => el.attend_status === 'pending').length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetStudentAttendances');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentAttendances');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentAttendances');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            // TODO: delete
            // this.GetStudentAttendances = async function (student_id, filters) {
            //     let filterItems = [];
            //     let specific_date = null;
            //     if (filters['filter_item']) {
            //         filters['filter_item'].forEach(elm => {
            //             if (elm.key === 'date') {
            //                 specific_date = elm.value;
            //             } else {
            //                 filterItems.push(elm);
            //             }
            //         })
            //         delete filters['filter_item'];
            //     }
            //     filterItems.push({ key: 'student_id', value: student_id });

            //     if (specific_date) {
            //         const year = parseInt(specific_date.substring(0, 4))
            //         const month = parseInt(specific_date.substring(5, 7))
            //         const startDate = new Date(year, month - 1, 1)
            //         filterItems.push({ key: 'start_date', value: `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? `0${startDate.getMonth() + 1}` : startDate.getMonth() + 1}-${startDate.getDate() < 10 ? `0${startDate.getDate()}` : startDate.getDate()}` });
            //         const lastDateOfMonth = new Date(year, month, 0)
            //         filterItems.push({ key: 'end_date', value: `${lastDateOfMonth.getFullYear()}-${lastDateOfMonth.getMonth() + 1 < 10 ? `0${lastDateOfMonth.getMonth() + 1}` : lastDateOfMonth.getMonth() + 1}-${lastDateOfMonth.getDate() < 10 ? `0${lastDateOfMonth.getDate()}` : lastDateOfMonth.getDate()}` })
            //     }

            //     const payload = {
            //         data_type: 'lesson_data',
            //         filter_item: filterItems,
            //         order_by: 'date',
            //         sort_dir: 'desc',
            //         ...filters
            //     };

            //     let limit = -1;
            //     let page = 0;
            //     if (DataValid(filters.filter_limit) && DataValid(filters.filter_page) && filters.filter_page <= 0) {
            //         limit = filters.filter_limit;
            //         page = filters.filter_page;

            //         delete payload['filter_limit'];
            //         delete payload['filter_page'];
            //     }

            //     try {
            //         const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
            //         if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
            //             const formattedData = data['data'].map(el => parseStudentAttendanceData(el, student_id));

            //             let filterFormattedData = formattedData;
            //             if (specific_date) {
            //                 filterFormattedData = filterFormattedData.filter(el => el.date === specific_date);
            //             }

            //             let temp = filterFormattedData;
            //             if (limit > -1 && page > -1) {
            //                 temp = filterFormattedData.slice(page * limit, page * limit + limit);
            //             }

            //             const returnedData = {
            //                 data: temp,
            //                 total: data.total,
            //                 attend: formattedData.filter(el => el.attend_status === 'attend').length,
            //                 absent: formattedData.filter(el => el.attend_status === 'absent').length,
            //                 pending: formattedData.filter(el => el.attend_status === 'pending').length,
            //             };
            //             devLog('----------> XHR [SUCCESS]: GetStudentAttendances');
            //             devLog(returnedData)
            //             return Promise.resolve(returnedData);
            //         } else {
            //             devLog('----------> XHR [NULL]: GetStudentAttendances');
            //             return Promise.reject([]);
            //         }
            //     } catch (res) {
            //         devLog('----------> XHR [FAIL]: GetStudentAttendances');
            //         devLog(res);
            //         return Promise.reject([])
            //     }
            // }

            this.ImportStudent = async function (students = []) {
                let formattedStudents = JSON.parse(JSON.stringify(students));
                for (let i = 0; i < formattedStudents.length; i++) {
                    formattedStudents[i] = convertCMSKeyToBackendKey(formattedStudents[i], STUDENT_MODEL);
                }

                try {
                    const uploaded = {
                        students: formattedStudents
                    }
                    const data = await Vue.prototype.$XHR.api('import_student', uploaded);
                    const invStudents = [];
                    const importeds = [];

                    for (let i = 0; i < data.length; i++) {
                        if (typeof data[i] === 'string') {
                            invStudents.push(`${students[i].student_name} (${students[i].username}): ${vm.$t(data[i] === 'username exist' ? 'message.accountExist' : data[i])}`);
                        } else if (typeof data[i] === 'object' && !data[i].id) {
                            invStudents.push(`${students[i].student_name} (${students[i].username}): ${vm.$t('message.errorOccur')}`);
                        } else {
                            const formattedData = parseStudentData(data[i]);
                            if (formattedData) {
                                importeds.push(formattedData);
                            } else {
                                invStudents.push(`${students[i].student_name} (${students[i].username}): ${vm.$t('message.errorOccur')}`);
                            }
                        }
                    }

                    if (invStudents.length > 0) {
                        devLog(invStudents);
                        return Promise.resolve({ result: 'fail', data: invStudents });
                    } else {
                        devLog('----------> XHR [SUCCESS]: ImportStudent');
                        devLog(importeds);
                        return Promise.resolve({ result: 'success', data: importeds });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: ImportStudent');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }







            /**
             * 新增課室
             * ==== Required ====
             * @param {String} name 
             * @param {Number} center - center id
             */
            this.NewClassroom = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, CLASSROOM_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_facility', uploaded);
                    const formattedData = parseClassroomData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewClassroom');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewClassroom');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewClassroom');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateClassroom = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, CLASSROOM_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('edit_facility', payload);
                    const formattedData = parseClassroomData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateClassroom');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateClassroom');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateClassroom');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * 課室管理 List
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             */
            this.GetClassrooms = async function (filters = {}) {
                let joinSchoolData = false;
                if (filters['join_school_data'] === true) {
                    joinSchoolData = true;
                    delete filters['join_school_data'];
                }

                const payload = {
                    data_type: 'facility_data',
                    ...filters
                };
                if (payload['filter_item'] && payload['filter_item'].length < 1) {
                    delete payload['filter_item'];
                }

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        let schoolData = [];

                        if (joinSchoolData === true) {
                            const idList = data['data'].map(el => el.center_id);
                            if (idList.length > 0) {
                                const res = await this.GetSchoolsByIdList(idList);
                                if (DataValid(res['data'])) {
                                    schoolData = res['data'];
                                }
                            }
                        }

                        const formattedData = data['data'].map(el => parseClassroomData(el, schoolData));
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetClassrooms');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetClassrooms');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetClassrooms');
                    devLog(res);
                    return Promise.reject([]);
                }
            }

            this.GetClassroomById = async function (id) {
                const payload = {
                    data_type: 'facility_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseClassroomData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetClassroomById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetClassroomById');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetClassroomById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteClassroom = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_facility', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteClassroom');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteClassroom');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }




            /**
             * 新增課程分類
             * ==== Required ====
             * @param {String} name 
             */
            this.NewCourseCategory = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, COURSE_CATEGORY_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('new_course_category', uploaded);
                    const formattedData = parseCourseCategoryData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewCourseCategory');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewCourseCategory');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewCourseCategory');
                    devLog(res);
                    switch (res.data) {
                        case 'category_code exist':
                            return Promise.reject(vm.$t('message.categoryCodeExist'));
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            this.UpdateCourseCategory = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, COURSE_CATEGORY_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('edit_course_category', payload);
                    const formattedData = parseCourseCategoryData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateCourseCategory');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateCourseCategory');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateCourseCategory');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetCourseCategories = async function (filters = {}) {
                let joinSchoolData = false;
                if (filters['join_school_data'] === true) {
                    joinSchoolData = true;
                    delete filters['join_school_data'];
                }

                const payload = {
                    data_type: 'course_category_data',
                    ...filters
                };
                if (payload['filter_item'] && payload['filter_item'].length < 1) {
                    delete payload['filter_item'];
                }

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        let schoolData = [];

                        if (joinSchoolData === true) {
                            const idList = [];
                            data['data'].forEach(el => {
                                if (DataValid(el.center)) {
                                    el.center.forEach(school_id => {
                                        if (!idList.includes(school_id)) {
                                            idList.push(school_id);
                                        }
                                    })
                                }
                            })
                            if (idList.length > 0) {
                                const res = await this.GetSchoolsByIdList(idList);
                                if (DataValid(res['data'])) {
                                    schoolData = res['data'];
                                }
                            }

                        }
                        const formattedData = data['data'].map(el => parseCourseCategoryData(el, schoolData));
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetCourseCategories');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetCourseCategories');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCourseCategories');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetCourseCategoriesByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'course_category_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id_list', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseCourseCategoryData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetCourseCategoriesByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetCourseCategoriesByIdList');
                        return Promise.resolve({
                            data: [],
                            total: 0
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCourseCategoriesByIdList');
                    devLog(res);
                    return Promise.resolve({
                        data: [],
                        total: 0
                    });
                }
            }

            this.GetCourseCategoryById = async function (id) {
                const payload = {
                    data_type: 'course_category_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseCourseCategoryData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetCourseCategoryById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetCourseCategoryById');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCourseCategoryById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteCourseCategory = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_course_category', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteCourseCategory');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteCourseCategory');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }




            /**
             * 新增課程
             * ==== Required ====
             * @param {String} name 
             */
            this.NewCourse = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, COURSE_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_course', uploaded);
                    const formattedData = parseCourseData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewCourse');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewCourse');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewCourse');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateCourse = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, COURSE_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('edit_course', payload);
                    const formattedData = parseCourseData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateCourse');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateCourse');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateCourse');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetCourses = async function (filters = {}) {
                let joinSchoolData = false;
                let joinCategoryData = false;
                if (filters['join_school_data'] === true) {
                    joinSchoolData = true;
                    delete filters['join_school_data'];
                }
                if (filters['join_category_data'] === true) {
                    joinCategoryData = true;
                    delete filters['join_category_data'];
                }

                const payload = {
                    data_type: 'course_data',
                    ...filters
                };
                if (payload['filter_item'] && payload['filter_item'].length < 1) {
                    delete payload['filter_item'];
                }

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        let schoolData = [];
                        let categoryData = [];

                        if (joinSchoolData === true) {
                            const idList = [];
                            data['data'].forEach(el => {
                                if (DataValid(el.center)) {
                                    el.center.forEach(school_id => {
                                        if (!idList.includes(school_id)) {
                                            idList.push(school_id);
                                        }
                                    })
                                }
                            })
                            if (idList.length > 0) {
                                const res = await this.GetSchoolsByIdList(idList);
                                if (DataValid(res['data'])) {
                                    schoolData = res['data'];
                                }
                            }
                        }

                        if (joinCategoryData === true) {
                            const idList = data['data'].map(el => el.course_category_id);
                            if (idList.length > 0) {
                                const res = await this.GetCourseCategoriesByIdList(idList);
                                if (DataValid(res['data'])) {
                                    categoryData = res['data'];
                                }
                            }

                        }

                        const formattedData = data['data'].map(el => parseCourseData(el, schoolData, categoryData));
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetCourses');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetCourses');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCourses');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetCourseById = async function (id) {
                const payload = {
                    data_type: 'course_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseCourseData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetCourseById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetCourseById');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCourseById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteCourse = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_course', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteCourse');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteCourse');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }






            /**
             * 新增商品分類
             * ==== Required ====
             * @param {String} name 
             */
            this.NewProductCategory = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, PRODUCT_CATEGORY_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_category', uploaded);
                    const formattedData = parseProductCategoryData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewProductCategory');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewProductCategory');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewProductCategory');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateProductCategory = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, PRODUCT_CATEGORY_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('edit_category', payload);
                    const formattedData = parseProductCategoryData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateProductCategory');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateProductCategory');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateProductCategory');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetProductCategories = async function (filters = {}) {
                const payload = {
                    data_type: 'category_data',
                    ...filters
                };
                if (payload['filter_item'] && payload['filter_item'].length < 1) {
                    delete payload['filter_item'];
                }

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parseProductCategoryData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetProductCategories');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetProductCategories');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetProductCategories');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetProductCategoriesByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'category_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id_list', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseProductCategoryData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetProductCategoriesByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetProductCategoriesByIdList');
                        return Promise.resolve({
                            data: [],
                            total: 0
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetProductCategoriesByIdList');
                    devLog(res);
                    return Promise.resolve({
                        data: [],
                        total: 0
                    });
                }
            }

            this.GetProductCategoryById = async function (id) {
                const payload = {
                    data_type: 'category_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseProductCategoryData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetProductCategoryById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetProductCategoryById');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetProductCategoryById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteProductCategory = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_category', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteProductCategory');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteProductCategory');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }




            /**
             * 新增商品
             * ==== Required ====
             * @param {String} name 
             */
            this.NewProduct = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, PRODUCT_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_product', uploaded);
                    const formattedData = parseProductData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewProduct');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewProduct');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewProduct');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateProduct = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, PRODUCT_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('edit_product', payload);
                    const formattedData = parseProductData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateProduct');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateProduct');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateProduct');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             * @param {Array<{key: string, value: any}>} filter_item
             * filter_item key:
             * - 'name': string
             */
            this.GetProducts = async function (filters = {}) {
                let joinCategoryData = false;

                if (filters['join_category_data'] === true) {
                    joinCategoryData = true;
                    delete filters['join_category_data'];
                }

                const payload = {
                    data_type: 'product_data',
                    ...filters
                };
                if (payload['filter_item'] && payload['filter_item'].length < 1) {
                    delete payload['filter_item'];
                }

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        let categoryData = [];

                        if (joinCategoryData === true) {
                            const idList = data['data'].map(el => el.category);
                            if (idList.length > 0) {
                                const res = await this.GetProductCategoriesByIdList(idList);
                                if (DataValid(res['data'])) {
                                    categoryData = res['data'];
                                }
                            }

                        }

                        const formattedData = data['data'].map(el => parseProductData(el, categoryData));
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetProducts');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetProducts');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetProducts');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetProductById = async function (id) {
                const payload = {
                    data_type: 'product_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseProductData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetProductById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetProductById');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetProductById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteProduct = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_product', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteProduct');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteProduct');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetProductSale = async function (id) {
                try {
                    const data = await Vue.prototype.$XHR.api('get_product_sales', { product_id: id });
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetProductSale');
                        let count = 0;
                        data.forEach(order => {
                            const found = order.product_id_list.find(el => el.id === id);
                            if (found) {
                                count += found.amount
                                if (order.status === 'debit') {
                                    const refund = order.debit_product_ids.find(el => el.id === id);
                                    if (refund) {
                                        count -= refund.amount;
                                    }
                                }
                            }
                        })
                        return Promise.resolve(count);
                    } else {
                        devLog('----------> XHR [NULL]: GetProductSale');
                        return Promise.resolve(0);
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetProductSale');
                    devLog(res);
                    return Promise.resolve(0);
                }
            }





            /**
             * 新增課程時間表 (New Lesson)
             * Single
             */
            this.NewSingleDateLesson = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, CLASS_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_lesson', uploaded);
                    const formattedData = parseLessonData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewSingleDateLesson');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewSingleDateLesson');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewSingleDateLesson');
                    devLog(res);
                    switch (res.data) {
                        case 'course_code exist':
                            return Promise.reject(vm.$t('message.courseCodeExist'));
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            /**
            * 新增課程時間表 (New Lesson)
            * Regular
            */
            this.NewRegularLesson = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, CLASS_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_regular', uploaded);
                    const formattedData = paresRegularSetting(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewRegularLesson');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewRegularLesson');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewRegularLesson');
                    devLog(res);
                    switch (res.data) {
                        case 'course_code exist':
                            return Promise.reject(vm.$t('message.courseCodeExist'));
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            /**
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             * @param {Array<{key: string, value: any}>} filter_item
             * filter_item key:
             * - 'date': string
             * - 'start_date': string
             * - 'end_date': string
             * - 'staff_id': number
             * - 'course_id': number
             * - 'course_id_list': Array<number>
             * - 'student_id': number
             * - 'course_code': string
             * - 'weekdays': Array<number>
             */
            this.GetLessons = async function (filters = {}) {
                let joinTutorData = false;
                if (filters['join_tutor_data'] === true) {
                    joinTutorData = true;
                    delete filters['join_tutor_data'];
                }

                let joinStudentData = false;
                if (filters['join_student_data'] === true) {
                    joinStudentData = true;
                    delete filters['join_student_data'];
                }

                const payload = {
                    data_type: 'lesson_data',
                    order_by: 'date',
                    sort_dir: 'asc',
                    ...filters
                };

                if (payload['filter_item'] && payload['filter_item'].length < 1) {
                    delete payload['filter_item'];
                }

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        let tutorData = [];
                        if (joinTutorData === true) {
                            const idList = data['data'].map(el => el.staff_id);
                            if (idList.length > 0) {
                                const res = await this.GetTutorsByIdList(idList);
                                if (DataValid(res['data'])) {
                                    tutorData = res['data'];
                                }
                            }
                        }

                        let studentData = [];
                        if (joinStudentData === true) {
                            const idList = [];
                            data['data'].forEach(el => {
                                if (DataValid(el.students)) {
                                    el.students.forEach(student_id => {
                                        idList.push(student_id);
                                    })
                                }
                            })

                            if (idList.length > 0) {
                                const res = await this.GetStudentsByIdList(idList);
                                if (DataValid(res['data'])) {
                                    studentData = res['data'];
                                }
                            }
                        }

                        const formattedData = data['data'].map(el => parseLessonData(el, tutorData, studentData));
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetLessons');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetLessons');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetLessons');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetLessonsByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'lesson_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id_list', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseLessonData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetLessonsByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetLessonsByIdList');
                        return Promise.resolve({ data: [], total: 0 });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetLessonsByIdList');
                    devLog(res);
                    return Promise.resolve({ data: [], total: 0 });
                }
            }

            this.GetLessonById = async function (id, filters = {}) {
                let joinStudentData = false;
                if (filters['join_student_data'] === true) {
                    joinStudentData = true;
                    delete filters['join_student_data'];
                }

                let joinAttendanceData = false;
                if (filters['join_attendance_data'] === true) {
                    joinAttendanceData = true;
                    delete filters['join_attendance_data'];
                }

                let joinStudentLastLesson = false;
                if (filters['join_student_last_lesson'] === true) {
                    joinStudentLastLesson = true;
                    delete filters['join_student_last_lesson'];
                }

                const payload = {
                    data_type: 'lesson_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    let studentData = [];
                    if (joinStudentData === true && DataValid(data.students) && data.students.length > 0) {
                        let res = null;
                        if (joinStudentLastLesson) {
                            res = await this.GetStudentsWithLastLessonByIdList(data.students);
                        } else {
                            res = await this.GetStudentsByIdList(data.students);
                        }
                     
                        if (res && DataValid(res['data'])) {
                            studentData = res['data'];
                        }
                    }

                    let lessonData = [];
                    if (joinAttendanceData === true && DataValid(data.attendance) && data.attendance.length > 0) {
                        const lessonIds = [];
                        data.attendance.forEach(el => {
                            if (el.from_lesson !== undefined && el.from_lesson !== null && el.from_lesson > -1) {
                                lessonIds.push(el.from_lesson);
                            }
                        })
                        if (lessonIds.length > 0) {
                            const res = await this.GetLessonsByIdList(lessonIds);
                            if (DataValid(res['data'])) {
                                lessonData = res['data'];
                            }
                        }
                    }

                    const formattedData = parseLessonData(data, [], studentData, lessonData);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetLessonById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetLessonById');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetLessonById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateLesson = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, LESSON_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('edit_lesson', payload);
                    const formattedData = parseLessonData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateLesson');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateLesson');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateLesson');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteLesson = async function (id_list) {
                try {
                    const data = await Vue.prototype.$XHR.api('bulk_delete_lesson', { id_list });
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: DeleteLesson');
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: DeleteLesson');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteLesson');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * update
             * @param {String} end_date
             */
            this.ExtendRegularLesson = async function (regular_id, days, update = {}) {
                const payload = {
                    regular_id,
                    days,
                    ...update
                };

                try {
                    const data = await Vue.prototype.$XHR.api('extend_regular', payload);
                    devLog('----------> XHR [SUCCESS]: ExtendRegularLesson');
                    const formattedData = [];
                    data.forEach(item => {
                        if (item && typeof item !== 'string' && DataValid(item.id)) {
                            formattedData.push(parseLessonData(item));
                        }
                    })
                    devLog(formattedData);
                    return Promise.resolve(formattedData);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: ExtendRegularLesson');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * 會update曬所有相關lesson嘅setting
             * ===== Required =====
             * @param {String} course_code
             * @param {String} date - update all lessons after this date
             */
            this.UpdateLessonSetting = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, CLASS_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }
                    await Vue.prototype.$XHR.api('edit_lesson_after', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateLessonSetting');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateLessonSetting');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateRegularSetting = async function (id, update) {
                const u = JSON.parse(JSON.stringify(update));
                if (u['course_code'] !== undefined) {
                    delete u['course_code'];
                }
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(u, CLASS_MODEL);

                    const payload = {
                        id: id,
                        ...formattedPayload
                    }
                    await Vue.prototype.$XHR.api('edit_regular', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateRegularSetting');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateRegularSetting');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.AddStudentToLessonWithPackage = async function (lesson_id, student_id) {
                try {
                    const payload = {
                        student_id,
                        lesson_id_list: [lesson_id]
                    }

                    await Vue.prototype.$XHR.api('assign_lesson', payload);

                    devLog('----------> XHR [SUCCESS]: AddStudentToLessonWithPackage');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: AddStudentToLessonWithPackage');
                    devLog(res);
                    switch (res.data) {
                        case 'lesson not enough':
                            return Promise.reject('message.noMorePackageQuota');
                        case 'student in lesson':
                            return Promise.reject('message.studentInLesson');
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }
            this.AddStudentToLesson = async function (lesson_id, student_id) {
                try {
                    const payload = {
                        student_id,
                        id: lesson_id
                    }

                    await Vue.prototype.$XHR.api('add_student_to_lesson', payload);

                    devLog('----------> XHR [SUCCESS]: AddStudentToLesson');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: AddStudentToLesson');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.SkipLesson = async function (lesson_id, student_id) {
                try {
                    await Vue.prototype.$XHR.api('skip_lesson', {
                        from_id: lesson_id,
                        student_id
                    });

                    devLog('----------> XHR [SUCCESS]: SkipLesson');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: SkipLesson');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * 轉堂(單一課堂)
             * @param {Number} student_id 
             * @param {Number} from_id - lesson id
             * @param {Number} to_id - lesson id
             */
            this.SwitchLesson = async function (student_id, from_id, to_id) {
                const payload = {
                    student_id,
                    from_id,
                    to_id
                };

                try {
                    await Vue.prototype.$XHR.api('switch_lesson', payload);
                    devLog('----------> XHR [SUCCESS]: SwitchLesson');
                    return Promise.resolve('success');
                } catch (res) {
                    devLog('----------> XHR [FAIL]: SwitchLesson');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.SwitchLessonWithPackage = async function (student_id, from_id, to_id) {
                const payload = {
                    student_id,
                    from_id,
                    to_id
                };

                try {
                    await Vue.prototype.$XHR.api('switch_lesson_package', payload);
                    devLog('----------> XHR [SUCCESS]: SwitchLessonWithPackage');
                    return Promise.resolve('success');
                } catch (res) {
                    devLog('----------> XHR [FAIL]: SwitchLessonWithPackage');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
            * 轉堂(揀一堂後，將未上果N堂都轉去依個course到)
            * @param {Number} student_id 
            * @param {Number} from_course_code - course id
            * @param {Number} to_course_code - course id
            * @param {String} start_date - 幾時開始轉
            */
            this.SwitchCourse = async function (student_id, from_course_code, to_course_code, start_date) {
                const payload = {
                    id: student_id,
                    from_course_code: from_course_code,
                    to_course_code: to_course_code,
                    start_date: start_date
                };

                try {
                    await Vue.prototype.$XHR.api('switch_course', payload);
                    devLog('----------> XHR [SUCCESS]: SwitchCourse');
                    return Promise.resolve('success');
                } catch (res) {
                    devLog('----------> XHR [FAIL]: SwitchCourse');
                    devLog(res);
                    switch (res.data) {
                        case 'target course not enough lesson':
                            return Promise.reject(vm.$t('message.targetCourseNotEnoughLesson'));
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            this.SwitchCourseWithPackage = async function (student_id, from_course_code, to_course_code, start_date) {
                const payload = {
                    id: student_id,
                    from_course_code: from_course_code,
                    to_course_code: to_course_code,
                    start_date: start_date
                };

                try {
                    await Vue.prototype.$XHR.api('switch_course_package', payload);
                    devLog('----------> XHR [SUCCESS]: SwitchCourseWithPackage');
                    return Promise.resolve('success');
                } catch (res) {
                    devLog('----------> XHR [FAIL]: SwitchCourseWithPackage');
                    devLog(res);
                    switch (res.data) {
                        case 'target course not enough lesson':
                            return Promise.reject(vm.$t('message.targetCourseNotEnoughLesson'));
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            /**
            * 退課
            * @param {Number} student_id 
            * @param {Number} from_course_code - course id
            * @param {String} start_date - 幾時開始轉
            */
            this.DropCourse = async function (student_id, from_course_code, start_date) {
                const payload = {
                    id: student_id,
                    from_course_code: from_course_code,
                    start_date: start_date
                };

                try {
                    await Vue.prototype.$XHR.api('drop_course', payload);
                    devLog('----------> XHR [SUCCESS]: DropCourse');
                    return Promise.resolve('success');
                } catch (res) {
                    devLog('----------> XHR [FAIL]: DropCourse');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
            * 退堂（單一課堂）
            */
            this.DropLesson = async function (lesson_id, student_id) {
                const payload = {
                    id: lesson_id,
                    student_id
                }

                try {
                    await Vue.prototype.$XHR.api('remove_student_from_lesson', payload);
                    await Vue.prototype.$XHR.api('remove_student', payload);
                    devLog('----------> XHR [SUCCESS]: DropLesson');
                    return Promise.resolve('success');
                } catch (res) {
                    devLog('----------> XHR [FAIL]: DropLesson');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetStudentUnpaidLessons = async function (student_id) {
                const payload = {
                    student_id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('unpaid_lesson', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseLessonData);
                        devLog('----------> XHR [SUCCESS]: GetStudentUnpaidLessons');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentUnpaidLessons');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentUnpaidLessons');
                    devLog(res);
                    return Promise.reject([]);
                }
            }



            /**
             * 繳交學費
             */
            this.GetPayTuitions = async function (filters = {}) {
                const payload = {
                    data_type: 'student_data',
                    ...filters
                };

                if (payload['filter_item'] && payload['filter_item'].length < 1) {
                    delete payload['filter_item'];
                }

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parsePayTuitionData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetPayTuitions');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetPayTuitions');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetPayTuitions');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetPayTuitionById = async function (id) {
                const payload = {
                    data_type: 'student_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parsePayTuitionData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetPayTuitionById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetPayTuitionById');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetPayTuitionById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }




            /**
             * 繳交學費
             * ==== Required ====
             * discount_type: 'percentage' | 'fix_amount'
             * if discount_type = 'percentage' --> Math.ceil(price * (discount_value/100) * 10) / 10
             * @param {Array<{id: -1, discount_type: '', discount_value: 0}>} lesson_id_list
             * @param {Array<{id: -1, discount_type: '', discount_value: 0, amount: 0 (qty)}>} product_id_list
             * @param {String} payment_method - 'cash'
             * @param {Number} subtotal
             * @param {String} remark
             * @param {Number} center_id
             * @param {Number} student_id
             * @param {String} status
             * @param {Array<Number>} except_lesson_id_list
             * ==== Optional ====
             * @param {String} payment_image
             * @param {Number} package_id - if have, lesson_id_list 只可有一個lesson
             * @param {Boolean} use_remain - case 本身有堂數剩下，如果個學生再買多個套票，會出alert問佢：會否將學生餘下的堂課數都報名這課程？
             * @param {String} discount_type
             * @param {Number} discount_value
             * if status == 'paid'
             * @param {String} pay_date - [YYYY-MM-DD]T[HH:MM:SS]Z
             * if 買package時加入unpaid嘅lessons
             * @param {Array<Number>} unpaid_lesson_id_list
             */
            this.NewOrder = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, ORDER_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_order', uploaded);
                    const formattedData = parseOrderData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewOrder');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewOrder');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewOrder');
                    devLog(res);
                    switch (res.data) {
                        case 'lesson get fail':
                        case 'lesson not found':
                            return Promise.reject(vm.$t('message.courseNotFound'));
                        case 'lesson get fail':
                        case 'lesson not found':
                            return Promise.reject(vm.$t('message.productNotFound'));
                        case 'product out of inventory':
                            return Promise.reject(vm.$t('message.outOfStock'));
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            /**
             * ==== Optional ====
             * @param {string} payment_method
             * @param {string} payment_image
             * @param {string} status
             * @param {string} remark
             */
            this.UpdateOrder = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, ORDER_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('edit_order', payload);
                    const formattedData = parseOrderData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateOrder');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateOrder');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateOrder');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ==== Required ====
             * @param {number} amount
             * @param {number} package_count
             * @param {Array<number>} lesson_ids
             * @param {Array<{ id: -1, amount: 0 }>} product_ids
             * @param {string} debit_remark
             */
            this.RefundOrder = async function (order_id, update) {
                try {
                    const payload = {
                        order_id,
                        ...update
                    }

                    const data = await Vue.prototype.$XHR.api('debit_order', payload);
                    devLog('----------> XHR [SUCCESS]: RefundOrder');
                    devLog(data)
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: RefundOrder');
                    devLog(res);
                    switch (res.data) {
                        case 'order missing / order debited':
                            return Promise.reject(vm.$t('message.orderMissingOrRefunded'));
                        case 'package quota not enough':
                            return Promise.reject(vm.$t('message.packageQuotaNotEnoughRefund'));
                        case 'lesson cannot debit':
                            return Promise.reject(vm.$t('message.lessonCannotRefund'));
                        case 'over amount':
                            return Promise.reject(vm.$t('message.refundAmountExceedOrderTotal'));
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            /**
             * 近期帳單
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             * @param {Array<{key: string, value: any}>} filter_item
             * filter_item key:
             * - 'create_date': string
             * - 'id': number
             * - 'student_name': string
             * - 'phone': string
             */
            this.GetOrders = async function (filters = {}) {
                const payload = {
                    data_type: 'order_data',
                    ...filters
                };
                if (payload['filter_item'] && payload['filter_item'].length < 1) {
                    delete payload['filter_item'];
                }

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parseOrderData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetOrders');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetOrders');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetOrders');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.ExportOrderInvoice = async function (order_id) {
                const payload = {
                    id: order_id,
                };

                try {
                    const data = await Vue.prototype.$XHR.api('export_pdf', payload);
                    devLog('----------> XHR [SUCCESS]: ExportOrderInvoice');
                    devLog(data);
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: ExportOrderInvoice');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.ExportOrderReport = async function (filter = {}) {
                const payload = {
                    ...filter
                };

                try {
                    const data = await Vue.prototype.$XHR.api('order_report', payload);
                    devLog('----------> XHR [SUCCESS]: ExportOrderReport');
                    devLog(data);
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: ExportOrderReport');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GenNextMonthPreviewOrder = async function (student_id) {
                const thisMonth = new Date();
                const nextMonth = thisMonth.getMonth() + 2;

                const payload = {
                    student_id,
                    month: `${thisMonth.getFullYear()}-${nextMonth < 10 ? ('0' + nextMonth) : nextMonth}`
                };

                try {
                    const data = await Vue.prototype.$XHR.api('extend_order_pre', payload);
                    const formattedData = parseOrderData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GenNextMonthPreviewOrder');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GenNextMonthPreviewOrder');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GenNextMonthPreviewOrder');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ==== Required ====
             * @param {number} student_id
             * @param {Array<number>} except_lesson_id_list
             * @param {Array<{id: -1, discount_type: '', discount_value: 0}>} lesson_id_list
             */
            this.GenNextMonthOrder = async function (payload) {
                const thisMonth = new Date();
                const nextMonth = thisMonth.getMonth() + 2;

                const final = {
                    ...payload,
                    month: `${thisMonth.getFullYear()}-${nextMonth < 10 ? ('0' + nextMonth) : nextMonth}`
                };

                try {
                    const data = await Vue.prototype.$XHR.api('extend_order', final);
                    const formattedData = parseOrderData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GenNextMonthOrder');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GenNextMonthOrder');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GenNextMonthOrder');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ===== Required =====
             * @param {number} student_id
             * @param {string} date - start date excluded
             * @param {Array<string>} course_codes
             */
            this.GetPackageLessonsPreview = async function (filters = {}) {
                try {
                    const data = await Vue.prototype.$XHR.api('get_package_lesson', filters);
                    if (DataValid(data)) {
                        let formattedData = data.map(parseLessonData);
                        devLog('----------> XHR [SUCCESS]: GetPackageLessonsPreview');
                        formattedData = formattedData.sort((a, b) => {
                            const aDate = new Date(`${a.date}T${a.start_time}:00Z`);
                            const bDate = new Date(`${b.date}T${b.start_time}:00Z`);
                            return aDate.getTime() - bDate.getTime();
                        })
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetPackageLessonsPreview');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetPackageLessonsPreview');
                    devLog(res);
                    return Promise.reject(null);
                }
            }






            /**
             * 新增套票
             * ==== Required ====
             * 
             */
            this.NewPackage = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, PACKAGE_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('new_package', uploaded);
                    const formattedData = parsePackageData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewPackage');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewPackage');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewPackage');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdatePackage = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, PACKAGE_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('edit_package', payload);
                    const formattedData = parsePackageData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdatePackage');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdatePackage');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdatePackage');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeletePackage = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_package', { id });

                    devLog('----------> XHR [SUCCESS]: DeletePackage');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeletePackage');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
            * 套票管理 List
            */
            this.GetPackages = async function (filters = {}) {
                const payload = {
                    data_type: 'package_data',
                    ...filters
                };
                if (payload['filter_item'] && payload['filter_item'].length < 1) {
                    delete payload['filter_item'];
                }

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parsePackageData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetPackages');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetPackages');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetPackages');
                    devLog(res);
                    return Promise.reject([]);
                }
            }

            this.GetPackageById = async function (id) {
                const payload = {
                    data_type: 'package_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parsePackageData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetPackageById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetPackageById');
                        return Promise.reject(vm.$t('message.errorOccur'));
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetPackageById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetStudentPackageInfo = async function (student_id) {
                const payload = {
                    student_id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('check_package', payload);
                    devLog('----------> XHR [SUCCESS]: GetStudentPackageInfo');
                    devLog(data)
                    return Promise.resolve(data);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentPackageInfo');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            // 未派堂
            this.GetStudentPackageQuota = async function (student_id) {
                const payload = {
                    student_id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('check_package', payload);
                    /**
                     * attendance 已出席 (已派堂)
                     * absent 已缺席 (已派堂)
                     * pending 未上堂 (已派堂)
                     * debit (已退款)
                     * skip 跳堂 (for reference only)
                     */
                    devLog('----------> XHR [SUCCESS]: GetStudentPackageQuota');
                    devLog(data)
                    const quota = data.total - data.attendance - data.absent - data.pending - data.debit;
                    return Promise.resolve(quota);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentPackageQuota');
                    devLog(res);
                    return Promise.resolve(0);
                }
            }

            this.GetPackagesByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'package_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id_list', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parsePackageData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetPackagesByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetPackagesByIdList');
                        return Promise.resolve({ data: [], total: 0 });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetPackagesByIdList');
                    devLog(res);
                    return Promise.resolve({ data: [], total: 0 });
                }
            }

        })()
    }
}