<script>
import Cookies from 'js-cookie'
import { mapActions } from 'vuex';
import { cannotAccessList } from '@/assets/cannotAccessList';
export default {
  name: 'TokenMixin',
  setup() {
    return {
      cookiesName: 'TUTOR_CMS_LID',
      langStorageName: 'TUTOR_CMS_LANG'
    }
  },
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog']),
    getCurrentUserData() {
      const data = Cookies.get(this.cookiesName)

      if (data !== undefined && data !== null && data !== '') {
        return JSON.parse(Cookies.get(this.cookiesName))
      }

      return {}
    },
    /**
     * @param {Object} data - { id: -1, user_type: '', username: '', schools: [], selected_school: -1, last_login: new Date().getTime() }
     */
    setUserData(data) {
      const userData = this.getCurrentUserData();
      if (this.$validate.DataValid(userData)) {
        Cookies.remove(this.cookiesName);
      }

      const expired = new Date();
      expired.setMonth(expired.getMonth() + 1); // expires after 1 month

      Cookies.set(this.cookiesName, JSON.stringify(data), {
        path: '/',
        expires: expired,
        sameSite: 'strict',
        secure: true,
      })
    },

    getUserType() {
      // 'developer' | 'manager' | 'admin' | 'tutor' | 'student'
      const userData = this.getCurrentUserData();
      return userData.user_type;
    },
    getUserSchools() {
      const userData = this.getCurrentUserData();
      return userData.schools || [];
    },
    getUserSelectedSchool() {
      const userData = this.getCurrentUserData();
      if (this.$validate.DataValid(userData.schools) && this.$validate.DataValid(userData.selected_school) && userData.schools.includes(userData.selected_school)) {
        return userData.selected_school;
      }

      return null;
    },
    async updateUserSchools(schools) {
      const check = this.checkUserData();
      if (check) {
        const userData = this.getCurrentUserData();
        const newData = {
          ...userData,
          schools: schools
        }

        await this.setUserData(newData);

        return true;
      }

      return false;
    },
    async updateUserSelectedSchool(selectedSchool) {
      const check = this.checkUserData();
      if (check) {
        const userData = this.getCurrentUserData();
        const newData = {
          ...userData,
          selected_school: selectedSchool
        }

        await this.setUserData(newData);

        return true;
      }

      return false;
    },
    removeUserCookies() {
      const userData = this.getCurrentUserData();
      if (this.$validate.DataValid(userData)) {
        Cookies.remove(this.cookiesName)
      }
    },
    checkUserData() {
      let resultType = false;
      const userData = this.getCurrentUserData();

      if (this.$validate.DataValid(userData)) {
        if (
          this.$validate.DataValid(userData.id) &&
          this.$validate.DataValid(userData.username) &&
          this.$validate.DataValid(userData.user_type)
        ) {
          resultType = true;
        }
      }

      if (!resultType) {
        this.forceLogout()
      } else {
        const linkName = this.$route.name;
        if (linkName && cannotAccessList[linkName] && cannotAccessList[linkName].length > 0 && cannotAccessList[linkName].includes(userData.user_type)) {
          resultType = false;
          this.setDialogMessage({
            message: 'noPermission',
            userType: userData.user_type
          });
          this.setShowDialog(true);
          this.$store.dispatch('toggleLoadingPage', false)
        }
      }

      return resultType;
    },
    async logout() {
      let redirect = {name: 'login'};
      const userData = this.getCurrentUserData()
      if (userData && userData.user_type === 'student') {
        redirect = { name: 'StudentLogin', params: { username: userData.username } }
      }
      await this.removeUserCookies();
      this.$router.replace(redirect);
    },
    async forceLogout() {
      this.$store.dispatch('toggleLoadingPage', true)
      await this.removeUserCookies();

      this.$store.dispatch('toggleAlertMessage', {
        show: true,
        message: 'message.loginExpired',
        type: 'error',
        refresh: false,
        redirect: 'login',
      })
    },

    getLangStorageName() {
      return this.langStorageName;
    }
  },
}
</script>
