/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router';
import store from '@/store'
import {
  cannotAccessList,
  defaultPage
} from '@/assets/cannotAccessList';

import TokenMixin from '@/mixins/TokenMixin'
import Validator from '@/prototype/Validator'
import Error from '@/views/Error.vue';
import i18n from '@/i18n';

Vue.use(VueRouter);
Vue.mixin(TokenMixin)
Vue.use(Validator)
const vm = new Vue({ i18n });

const routes = [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: vm.$t('login'),
      skipAuth: true,
    },
  },
  {
    path: '/student-login/:username?',
    name: 'StudentLogin',
    component: () => import('@/views/pages/StudentLogin.vue'),
    meta: {
      layout: 'blank',
      title: vm.$t('login'),
      skipAuth: true,
      isStudent: true,
    },
  },
  {
    path: '/student-info/:username?',
    name: 'StudentUserDetails',
    component: () => import('@/views/pages/student/StudentDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.studentInfo'),
      requireAuth: true,
      cannotAccess: cannotAccessList['StudentUserDetails'],
      isStudent: true,
    },
  },
  {
    path: '/student-attendance/:username',
    name: 'StudentUserAttendance',
    component: () => import('@/views/pages/user/Student/StudentAttendance.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('attendanceRecord'),
      requireAuth: true,
      cannotAccess: cannotAccessList['StudentUserAttendance'],
      isStudent: true,
    },
  },
  {
    path: '/student-order/:username',
    name: 'StudentUserOrderList',
    component: () => import('@/views/pages/user/Order/OrderList.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.order'),
      requireAuth: true,
      cannotAccess: cannotAccessList['StudentUserOrderList'],
      isStudent: true,
    },
  },
  {
    path: '/pay-tuition',
    name: 'PayTuitionList',
    component: () => import('@/views/pages/user/PayTuition/PayTuitionList.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.payTuition'),
      requireAuth: true,
      cannotAccess: cannotAccessList['PayTuitionList'],
    },
  },
  {
    path: '/pay-tuition/payment/:id',
    name: 'PayTuitionPayment',
    component: () => import('@/views/pages/user/PayTuition/PayTuitionPayment.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.payTuition'),
      requireAuth: true,
      cannotAccess: cannotAccessList['PayTuitionPayment'],
    },
  },
  {
    path: '/timetable',
    name: 'Timetable',
    component: () => import('@/views/pages/user/CourseTimetable/Timetable.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.timetable'),
      requireAuth: true,
      cannotAccess: cannotAccessList['Timetable'],
    },
  },
  {
    path: '/timetable/class/create',
    name: 'CreateClass',
    component: () => import('@/views/pages/user/CourseTimetable/CreateClass.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('createTimetable'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateClass'],
    },
  },
  {
    path: '/timetable/lesson/:id',
    name: 'ClassDetails',
    component: () => import('@/views/pages/user/CourseTimetable/ClassDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.timetable'),
      requireAuth: true,
      cannotAccess: cannotAccessList['ClassDetails'],
    },
  },
  {
    path: '/student',
    name: 'StudentList',
    component: () => import('@/views/pages/user/Student/StudentList.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.student'),
      requireAuth: true,
      cannotAccess: cannotAccessList['StudentList'],
    },
  },
  {
    path: '/student/details/:id',
    name: 'StudentDetails',
    component: () => import('@/views/pages/user/Student/StudentDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('studentDetails'),
      requireAuth: true,
      cannotAccess: cannotAccessList['StudentDetails'],
    },
  },
  {
    path: '/student/create',
    name: 'CreateStudent',
    component: () => import('@/views/pages/user/Student/StudentDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('createStudent'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateStudent'],
    },
  },
  {
    path: '/student/attendance/:id',
    name: 'StudentAttendance',
    component: () => import('@/views/pages/user/Student/StudentAttendance.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('attendanceRecord'),
      requireAuth: true,
      cannotAccess: cannotAccessList['StudentAttendance'],
    },
  },
  {
    path: '/course',
    name: 'CourseList',
    component: () => import('@/views/pages/user/Course/CourseList.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.allCourses'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CourseList'],
    },
  },
  {
    path: '/course/details/:id',
    name: 'CourseDetails',
    component: () => import('@/views/pages/user/Course/CourseDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('courseDetails'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CourseDetails'],
    },
  },
  {
    path: '/course/create',
    name: 'CreateCourse',
    component: () => import('@/views/pages/user/Course/CourseDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('createCourse'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateCourse'],
    },
  },
  {
    path: '/course-category',
    name: 'CourseCategoryList',
    component: () => import('@/views/pages/user/Course/CourseCategoryList.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.courseCategory'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CourseCategoryList'],
    },
  },
  {
    path: '/course-category/create',
    name: 'CreateCourseCategory',
    component: () => import('@/views/pages/user/Course/CourseCategoryDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('createCategory'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateCourseCategory'],
    },
  },
  {
    path: '/course-category/details/:id',
    name: 'CourseCategoryDetails',
    component: () => import('@/views/pages/user/Course/CourseCategoryDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('courseCategoryDetails'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CourseCategoryDetails'],
    },
  },
  {
    path: '/order/:sid?',
    name: 'OrderList',
    component: () => import('@/views/pages/user/Order/OrderList.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('recentInvoice'),
      requireAuth: true,
      cannotAccess: cannotAccessList['OrderList'],
    },
  },
  {
    path: '/product',
    name: 'ProductList',
    component: () => import('@/views/pages/user/Product/ProductList.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.product'),
      requireAuth: true,
      cannotAccess: cannotAccessList['ProductList'],
    },
  },
  {
    path: '/product/details/:id',
    name: 'ProductDetails',
    component: () => import('@/views/pages/user/Product/ProductDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('productDetails'),
      requireAuth: true,
      cannotAccess: cannotAccessList['ProductDetails'],
    },
  },
  {
    path: '/product/create',
    name: 'CreateProduct',
    component: () => import('@/views/pages/user/Product/ProductDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('createProduct'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateProduct'],
    },
  },
  {
    path: '/product-category',
    name: 'ProductCategoryList',
    component: () => import('@/views/pages/user/Product/ProductCategoryList.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.productCategory'),
      requireAuth: true,
      cannotAccess: cannotAccessList['ProductCategoryList'],
    },
  },
  {
    path: '/product-category/details/:id',
    name: 'ProductCategoryDetails',
    component: () => import('@/views/pages/user/Product/ProductCategoryDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('productCategoryDetails'),
      requireAuth: true,
      cannotAccess: cannotAccessList['ProductCategoryDetails'],
    },
  },
  {
    path: '/product-category/create',
    name: 'CreateProductCategory',
    component: () => import('@/views/pages/user/Product/ProductCategoryDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('createCategory'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateProductCategory'],
    },
  },
  {
    path: '/classroom',
    name: 'ClassroomList',
    component: () => import('@/views/pages/user/Classroom/ClassroomList.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.classroom'),
      requireAuth: true,
      cannotAccess: cannotAccessList['ClassroomList'],
    },
  },
  {
    path: '/classroom/details/:id',
    name: 'ClassroomDetails',
    component: () => import('@/views/pages/user/Classroom/ClassroomDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('classroomDetails'),
      requireAuth: true,
      cannotAccess: cannotAccessList['ClassroomDetails'],
    },
  },
  {
    path: '/classroom/create',
    name: 'CreateClassroom',
    component: () => import('@/views/pages/user/Classroom/ClassroomDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('createClassroom'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateClassroom'],
    },
  },
  {
    path: '/school',
    name: 'SchoolList',
    component: () => import('@/views/pages/user/School/SchoolList.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.campus'),
      requireAuth: true,
      cannotAccess: cannotAccessList['SchoolList'],
    },
  },
  {
    path: '/school/details/:id',
    name: 'SchoolDetails',
    component: () => import('@/views/pages/user/School/SchoolDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('campusDetails'),
      requireAuth: true,
      cannotAccess: cannotAccessList['SchoolDetails'],
    },
  },
  {
    path: '/school/create',
    name: 'CreateSchool',
    component: () => import('@/views/pages/user/School/SchoolDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('createCampus'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateSchool'],
    },
  },
  {
    path: '/tutor',
    name: 'TutorList',
    component: () => import('@/views/pages/user/Tutor/TutorList.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.tutor'),
      requireAuth: true,
      cannotAccess: cannotAccessList['TutorList'],
    },
  },
  {
    path: '/tutor/details/:id',
    name: 'TutorDetails',
    component: () => import('@/views/pages/user/Tutor/TutorDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('tutorDetails'),
      requireAuth: true,
      cannotAccess: cannotAccessList['TutorDetails'],
    },
  },
  {
    path: '/tutor/create',
    name: 'CreateTutor',
    component: () => import('@/views/pages/user/Tutor/TutorDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('createTutor'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateTutor'],
    },
  },
  {
    path: '/promotion',
    name: 'PromotionList',
    component: () => import('@/views/pages/user/Promotion.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.promotion'),
      requireAuth: true,
      cannotAccess: cannotAccessList['PromotionList'],
    },
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('@/views/pages/user/Setting.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.setting'),
      requireAuth: true,
      cannotAccess: cannotAccessList['Setting'],
    },
  },
  {
    path: '/enquiry',
    name: 'EnquiryList',
    component: () => import('@/views/pages/user/Enquiry.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.enquiry'),
      requireAuth: true,
      cannotAccess: cannotAccessList['EnquiryList'],
    },
  },
  {
    path: '/package',
    name: 'PackageList',
    component: () => import('@/views/pages/user/Package/PackageList.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('menu.package'),
      requireAuth: true,
      cannotAccess: cannotAccessList['PackageList'],
    },
  },
  {
    path: '/package/details/:id',
    name: 'PackageDetails',
    component: () => import('@/views/pages/user/Package/PackageDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('packageDetails'),
      requireAuth: true,
      cannotAccess: cannotAccessList['PackageDetails'],
    },
  },
  {
    path: '/package/create',
    name: 'CreatePackage',
    component: () => import('@/views/pages/user/Package/PackageDetails.vue'),
    meta: {
      layout: 'content',
      title: vm.$t('createPackage'),
      requireAuth: true,
      cannotAccess: cannotAccessList['CreatePackage'],
    },
  },
  {
    path: '*',
    name: 'Error',
    component: Error,
    meta: { 
      layout: 'blank',
      title: 'Error',
      skipAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('toggleLoadingPage', false);
  store.dispatch('setLoading', false);
  window.scrollTo(0, 0);

  if (to.meta.requireAuth) {
    const userData = vm.getCurrentUserData();
    const isUserLogin = vm.$validate.DataValid(userData);

    if (isUserLogin) {
      if (to.meta.cannotAccess && to.meta.cannotAccess.includes(userData.user_type)) {
        next(defaultPage[userData.user_type])
      } else {
        next()
      }
    } else {
      if (to.meta.isStudent) {
        next('/student-login')
      } else {
        next('/login')
      }
    }
  } else if (to.meta.skipAuth) {
    const userData = vm.getCurrentUserData();
    if (vm.$validate.DataValid(userData) && userData.user_type) {
      if (to.name === 'login' || to.name === 'StudentLogin' || to.meta.cannotAccess && to.meta.cannotAccess.includes(userData.user_type)) {
        next(defaultPage[userData.user_type]);
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }
})



const DEFAULT_TITLE = 'CMS'
const STUDENT_DEFAULT_TITLE = '補習社'

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    let store = DEFAULT_TITLE;
    if (to.meta.isStudent) {
      store = STUDENT_DEFAULT_TITLE;
    }
    to.meta.storeName = store;
    document.title = (`${to.meta.title} - ` || '') + store
  })
})

export default router
