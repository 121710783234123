<template>
  <v-container class="alert-pop">
    <v-alert width="250px" v-model="showStatus" :type="alertType" transition="slide-x-reverse-transition">
      {{ $t(text) }}
    </v-alert>
  </v-container>
</template>

<script>

export default {
  name: 'AlertBox',
  props: ['type', 'show', 'text'],
  data: () => ({
    showStatus: false,
    timeout: -1,
    timeOutClock: null,
    alertType: 'success',
  }),
  watch: {
    show: function (newVal) {
      this.showStatus = newVal
      if (newVal) {
        if (this.timeOutClock != null) {
          clearTimeout(this.timeOutClock)
        }

        this.timeOutClock = setTimeout(() => {
          this.closeAlertBox()
        }, 2000)
      }
    },
    type: function (newVal) {
      this.alertType = newVal
    },
  },
  methods: {
    closeAlertBox() {
      clearTimeout(this.timeOutClock)
      this.timeOutClock = null
      if (this.$store.getters.alertMessage.refresh) {
        this.$store.dispatch('setLoading', false);
        window.scrollTo(0, 0);
        this.$router.go();
        
      } else if (this.$validate.DataValid(this.$store.getters.alertMessage.redirect)) {
        this.$store.dispatch('setLoading', false);

        let redirect = { name: this.$store.getters.alertMessage.redirect };

        if (this.$store.getters.alertMessage.redirect === 'login' || this.$store.getters.alertMessage.redirect === 'StudentLogin') {
          const userData = this.getCurrentUserData()
          if (userData) {
            if (userData.user_type === 'student') {
              redirect = { name: 'StudentLogin', params: { username: userData.username }}
            } else {
              redirect = { name: 'login'}
            }
          }

          this.removeUserCookies()
        }
        this.$router.replace(redirect)
      }
      this.$store.dispatch('toggleAlertMessage')
      if (this.$store.getters.showOverlay) {
        this.$store.dispatch('toggleOverlay')
      }
      if (this.$store.getters.showLoadingPage) {
        this.$store.dispatch('toggleLoadingPage')
      }
    },
  },
  beforeDestroy() {
    if (this.timeOutClock != null) {
      clearTimeout(this.timeOutClock)
    }
  },
}
</script>
