<template>
  <v-app>
    <v-app-bar
      flat
      app
      color="white"
      height="60"
      :style="{ 'margin-left': $vuetify.breakpoint.mdAndDown ? '0' : '8px' }"
    >
      <!-- icon for mobile nav drawer -->
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown" @click.stop="isDrawerOpen = !isDrawerOpen">
        <v-icon color="colorBlack" class="fi fi-rr-arrow-alt-square-right" style="font-size: 22px"></v-icon>
      </v-app-bar-nav-icon>

      <v-spacer />

      <!-- for desktop app bar -->
      <v-menu
        flat
        bottom
        offset-y
        nudge-bottom="10"
        rounded="lg"
        content-class="elevation-0 elevation-custom"
        transition="slide-y-transition"
        v-model="isUserMenuOpen"
        :close-on-content-click="!disableCloseMenu"
      >
        <!-- User Display Button -->
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" depressed text class="user-menu-open-button">
            <span>{{ userName }}{{ $validate.DataValid(schoolName) ? ' | ' : '' }}{{ schoolName }}</span>
            <v-icon class="ml-8">
              {{ isUserMenuOpen ? icon.mdiChevronUp : icon.mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>

        <v-list class="px-2" dense nav>
          <NavListItem
            v-if="userType !== 'student'"
            class="user-menu-item logout-btn"
            icon="fi fi-rr-replace"
            @btnClick="schoolSelectDialogOpen = true"
            :name="$t('switchCampus')"
          ></NavListItem>
        </v-list>

        <v-list class="px-2" dense nav>
          <NavListItem
            class="user-menu-item logout-btn"
            icon="fi fi-rr-language"
            @btnClick="openLangSelector()"
            :name="$t('language')"
          ></NavListItem>
        </v-list>

        <v-list class="px-2" dense nav>
          <NavListItem
            class="user-menu-item logout-btn"
            icon="fi fi-rr-sign-in-alt"
            @btnClick="logoutAndCloseMenu()"
            :name="$t('logout')"
          ></NavListItem>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- for navigation drawer -->
    <NavDrawer :drawerItemList="drawerItems" :isDrawerOpen.sync="isDrawerOpen"></NavDrawer>

    <DialogLayout
      :openDialog="schoolSelectDialogOpen"
      title="selectCampus"
      enableScroll
      btnAlign="center"
      eager
      @close="updateSelectedSchool($event)"
    >
      <v-radio-group v-model="tempSelectedSchoolId">
        <v-radio
          v-for="item in tutorSchoolOptions"
          :key="item.value"
          :label="item.text"
          :value="item.value"
          hide-details
        ></v-radio>
      </v-radio-group>
    </DialogLayout>

    <DialogLayout
      :openDialog="languageSelectDialogOpen"
      title="chooseLanguage"
      enableScroll
      btnAlign="center"
      eager
      @close="updateSelectedLang($event)"
    >
      <v-radio-group v-model="selectedLang">
        <v-radio
          v-for="item in langOptions"
          :key="item.value"
          :label="item.text"
          :value="item.value"
          hide-details
        ></v-radio>
      </v-radio-group>
    </DialogLayout>

    <v-main>
      <div :class="mainContentClass">
        <v-fade-transition mode="out-in">
          <div>
            <template>
              <slot> </slot>
            </template>
          </div>
        </v-fade-transition>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mdiChevronUp, mdiChevronDown } from '@mdi/js'

import NavListItem from '@/components/NavDrawer/NavListItem.vue'
import NavDrawer from '@/components/NavDrawer/NavDrawer.vue'
import { cannotAccessList } from '@/assets/cannotAccessList'
import DialogLayout from '@/components/layout/DialogLayout.vue'

export default {
  components: {
    NavListItem,
    NavDrawer,
    DialogLayout,
  },
  data: () => ({
    icon: {
      mdiChevronUp,
      mdiChevronDown,
    },
    isDrawerOpen: false,
    isUserMenuOpen: false,
    disableCloseMenu: false,

    drawerItems: [],

    userId: null,
    userName: '',
    userType: '',

    tutorSchoolOptions: [],
    schoolName: '',
    tempSelectedSchoolId: null,
    userSchoolIds: [],
    schoolSelectDialogOpen: false,

    languageSelectDialogOpen: false,
    selectedLang: 'zh',
    langOptions: [
      { value: 'zh', text: '繁體中文' },
      { value: 'en', text: 'English' },
    ],
  }),
  computed: {
    mainContentClass() {
      return {
        'fullW-container pt-5 pb-2 px-2': this.$route.name === 'PayTuitionPayment',
        'app-content-container boxed-container py-6 px-3 px-sm-6 px-md-6 px-lg-6 px-xl-6':
          this.$route.name !== 'PayTuitionPayment',
      }
    },
    getFullPath() {
      return this.$route.path
    },
  },
  watch: {
    getFullPath() {
      this.getSchoolData()
    },
  },
  methods: {
    initDrawerItems() {
      this.drawerItems = [
        {
          isGroup: false,
          id: 'pay-tuition',
          text: this.$t('menu.payTuition'),
          linkName: 'PayTuitionList',
          icon: 'fi fi-rr-piggy-bank',
          show: !cannotAccessList['PayTuitionList'] || !cannotAccessList['PayTuitionList'].includes(this.userType),
          subPages: ['PayTuitionPayment'],
        },
        {
          isGroup: false,
          id: 'timetable',
          text: this.$t('menu.timetable'),
          linkName: 'Timetable',
          icon: 'fi fi-rr-calendar',
          show: !cannotAccessList['Timetable'] || !cannotAccessList['Timetable'].includes(this.userType),
          subPages: ['CreateClass', 'ClassDetails'],
        },
        {
          isGroup: false,
          id: 'student',
          text: this.$t('menu.student'),
          linkName: 'StudentList',
          icon: 'fi fi-rr-grin',
          show: !cannotAccessList['StudentList'] || !cannotAccessList['StudentList'].includes(this.userType),
          subPages: ['StudentDetails', 'CreateStudent', 'StudentAttendance'],
        },
        {
          isGroup: false,
          id: 'student-user',
          text: this.$t('menu.studentInfo'),
          linkName: 'StudentUserDetails',
          icon: 'fi fi-rr-grin',
          show: !cannotAccessList['StudentUserDetails'] || !cannotAccessList['StudentUserDetails'].includes(this.userType),
          subPages: [],
        },
        {
          isGroup: false,
          id: 'student-user-attendance',
          text: this.$t('attendanceRecord'),
          linkName: 'StudentUserAttendance',
          icon: 'fi fi-rr-memo',
          show: !cannotAccessList['StudentUserAttendance'] || !cannotAccessList['StudentUserAttendance'].includes(this.userType),
          subPages: [],
        },
        {
          isGroup: false,
          id: 'student-user-order',
          text: this.$t('menu.order'),
          linkName: 'StudentUserOrderList',
          icon: 'fi fi-rr-donate',
          show: !cannotAccessList['StudentUserOrderList'] || !cannotAccessList['StudentUserOrderList'].includes(this.userType),
          subPages: [],
        },
        {
          isGroup: true,
          id: 'course',
          text: this.$t('menu.course'),
          icon: 'fi fi-rr-graduation-cap',
          show:
            !cannotAccessList['CourseList'] ||
            !cannotAccessList['CourseList'].includes(this.userType) ||
            !cannotAccessList['CourseCategoryList'] ||
            !cannotAccessList['CourseCategoryList'].includes(this.userType),
          items: [
            {
              show: !cannotAccessList['CourseList'] || !cannotAccessList['CourseList'].includes(this.userType),
              text: this.$t('menu.allCourses'),
              linkName: 'CourseList',
              subPages: ['CourseDetails', 'CreateCourse'],
            },
            {
              show:
                !cannotAccessList['CourseCategoryList'] ||
                !cannotAccessList['CourseCategoryList'].includes(this.userType),
              text: this.$t('menu.courseCategory'),
              linkName: 'CourseCategoryList',
              subPages: ['CourseCategoryDetails', 'CreateCourseCategory'],
            },
          ],
        },
        {
          isGroup: false,
          id: 'order',
          text: this.$t('menu.order'),
          linkName: 'OrderList',
          icon: 'fi fi-rr-donate',
          show: !cannotAccessList['OrderList'] || !cannotAccessList['OrderList'].includes(this.userType),
        },
        {
          isGroup: true,
          id: 'product',
          text: this.$t('menu.product'),
          icon: 'fi fi-rr-cube',
          show:
            !cannotAccessList['ProductList'] ||
            !cannotAccessList['ProductList'].includes(this.userType) ||
            !cannotAccessList['ProductCategoryList'] ||
            !cannotAccessList['ProductCategoryList'].includes(this.userType),
          items: [
            {
              show: !cannotAccessList['ProductList'] || !cannotAccessList['ProductList'].includes(this.userType),
              text: this.$t('menu.allProducts'),
              linkName: 'ProductList',
              subPages: ['ProductDetails', 'CreateProduct'],
            },
            {
              show:
                !cannotAccessList['ProductCategoryList'] ||
                !cannotAccessList['ProductCategoryList'].includes(this.userType),
              text: this.$t('menu.productCategory'),
              linkName: 'ProductCategoryList',
              subPages: ['ProductCategoryDetails', 'CreateProductCategory'],
            },
          ],
        },
        {
          isGroup: false,
          id: 'package',
          text: this.$t('menu.package'),
          linkName: 'PackageList',
          icon: 'fi fi-rr-ticket',
          show: !cannotAccessList['PackageList'] || !cannotAccessList['PackageList'].includes(this.userType),
          subPages: ['PackageDetails', 'CreatePackage'],
        },
        {
          isGroup: false,
          id: 'classroom',
          text: this.$t('menu.classroom'),
          linkName: 'ClassroomList',
          icon: 'fi fi-rr-door-open',
          show: !cannotAccessList['ClassroomList'] || !cannotAccessList['ClassroomList'].includes(this.userType),
          subPages: ['ClassroomDetails', 'CreateClassroom'],
        },
        {
          isGroup: false,
          id: 'school',
          text: this.$t('menu.campus'),
          linkName: 'SchoolList',
          icon: 'fi fi-rr-book-open-cover',
          show: !cannotAccessList['SchoolList'] || !cannotAccessList['SchoolList'].includes(this.userType),
          subPages: ['SchoolDetails', 'CreateSchool'],
        },
        {
          isGroup: false,
          id: 'tutor',
          text: this.$t('menu.tutor'),
          linkName: 'TutorList',
          icon: 'fi fi-rr-chalkboard-user',
          show: !cannotAccessList['TutorList'] || !cannotAccessList['TutorList'].includes(this.userType),
          subPages: ['TutorDetails', 'CreateTutor'],
        },
        {
          isGroup: false,
          id: 'promotion',
          text: this.$t('menu.promotion'),
          linkName: 'PromotionList',
          icon: 'fi fi-rr-gift',
          show: !cannotAccessList['PromotionList'] || !cannotAccessList['PromotionList'].includes(this.userType),
        },
        {
          isGroup: false,
          id: 'setting',
          text: this.$t('menu.setting'),
          linkName: 'Setting',
          icon: 'fi fi-rr-settings',
          show: !cannotAccessList['Setting'] || !cannotAccessList['Setting'].includes(this.userType),
        },
        {
          isGroup: false,
          id: 'enquiry',
          text: this.$t('menu.enquiry'),
          linkName: 'EnquiryList',
          icon: 'fi fi-rr-mailbox',
          show: !cannotAccessList['EnquiryList'] || !cannotAccessList['EnquiryList'].includes(this.userType),
        },
      ]
    },

    logoutAndCloseMenu() {
      this.disableCloseMenu = false
      this.logout()
    },

    // ==== For render menu bar 分校
    async getSchoolData() {
      try {
        const { data } = await this.$Fetcher.GetSchools()
        const filtered = data.filter(el => el.active === true)
        if (this.$validate.DataValid(filtered)) {
          this.tutorSchoolOptions = filtered.map(el => {
            return {
              value: el.id,
              text: el.name,
            }
          })

          await this.getUserSchoolsList()
        }
      } catch (err) {
        this.$common.error(err)
        this.dispatchSiteLoaded()
      }
    },

    async getUserSchoolsList() {
      this.tempSelectedSchoolId = null
      if (this.$validate.DataValid(this.userId)) {
        try {
          const data = await this.$Fetcher.GetTutorById(this.userId)
          let _selectedSchoolId = null
          const selectedSchoolId = this.getUserSelectedSchool()

          if (this.$validate.DataValid(data.schools)) {
            this.userSchoolIds = this.$common.duplicateData(data.schools)
          } else if (data.user_type === 'developer') {
            // All schools
            this.userSchoolIds = this.tutorSchoolOptions.map(el => el.value)
            await this.updateUserSchools(this.userSchoolIds)
          }

          if (selectedSchoolId === null) {
            if (this.$validate.DataValid(this.userSchoolIds)) {
              _selectedSchoolId = this.userSchoolIds[0]
              await this.updateUserSelectedSchool(this.userSchoolIds[0])
            }
          } else {
            _selectedSchoolId = selectedSchoolId
          }

          this.tutorSchoolOptions = this.tutorSchoolOptions.filter(el => this.userSchoolIds.includes(el.value))
          this.tempSelectedSchoolId = _selectedSchoolId

          this.schoolName = ''
          if (this.$validate.DataValid(_selectedSchoolId)) {
            const target = this.tutorSchoolOptions.find(item => item.value === _selectedSchoolId)
            if (target) {
              this.schoolName = target.text
            }
          }
        } catch (err) {
          this.$common.error(err)
        } finally {
          this.dispatchSiteLoaded()
        }
      }
    },

    async updateSelectedSchool(action) {
      if (action === true) {
        const updated = await this.updateUserSelectedSchool(this.tempSelectedSchoolId)
        if (updated) {
          let nextName = ''
          for (let i = 0; i < this.drawerItems.length; i++) {
            if (this.drawerItems[i].isGroup && this.$validate.DataValid(this.drawerItems[i].items)) {
              this.drawerItems[i].items.forEach(el => {
                if (el.subPages && el.subPages.includes(this.$route.name)) {
                  nextName = el.linkName
                }
              })
            } else {
              if (this.drawerItems[i].subPages && this.drawerItems[i].subPages.includes(this.$route.name)) {
                nextName = this.drawerItems[i].linkName
              }
            }

            if (nextName !== '') {
              break
            }
          }

          if (nextName !== '') {
            this.$router.push({ name: nextName })
          } else {
            this.$router.go()
          }

          this.schoolSelectDialogOpen = false
        } else {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.updateFail',
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }
      } else {
        const selectedSchoolId = this.getUserSelectedSchool()
        this.tempSelectedSchoolId = this.$common.duplicateData(selectedSchoolId)
        this.schoolSelectDialogOpen = false
      }
    },

    openLangSelector() {
      this.selectedLang = this.$i18n.locale
      this.languageSelectDialogOpen = true
    },
    updateSelectedLang(action) {
      if (action === true) {
        const sl = this.getLangStorageName();
        localStorage.setItem(sl, this.selectedLang)
        this.$i18n.locale = this.selectedLang
        window.scrollTo(0, 0)
        this.$router.go()
      }
      this.languageSelectDialogOpen = false
    
    },

    dispatchSiteLoaded() {
      const loaded = new Event('onSiteLoaded')
      window.dispatchEvent(loaded)
    },
  },
  async mounted() {
    const userData = await this.getCurrentUserData()
    if (this.$validate.DataValid(userData)) {
      this.userName = userData.username
      this.userId = userData.id
      this.getSchoolData()
    }
  },
  async created() {
    const sl = this.getLangStorageName();
    const lang = localStorage.getItem(sl)
    if (lang) {
      this.selectedLang = lang
    }

    const check = await this.checkUserData()
    if (check) {
      this.userType = await this.getUserType()
      this.initDrawerItems()
    }
  },
}
</script>

<style lang="scss" scoped>
.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.fullW-container {
  max-width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .fullW-container {
    max-width: calc(100% - 20px);
  }
}
</style>
