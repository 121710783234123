import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zh from "./zh.json";
import en from "./en.json";
import dateZh from "./dateZh.json";
import dateEn from "./dateEn.json";

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: localStorage.getItem('TUTOR_CMS_LANG') || "zh",
    messages: {
        zh,
        en
    },
    dateTimeFormats: {
        zh: dateZh,
        en: dateEn
    },
    fallbackLocale: "zh",
});

export default i18n;